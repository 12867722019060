/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Tag from "../../Tag.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.readOnly ? block1.call(this, context, tags, suffix) : block2.call(this, context, tags, suffix) } `;}
function block1 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="${ifDefined(this.tagClasses)}"><span class="udex-tag__text">${unsafeHTML(this.text)}</span></div>`;}
function block2 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.href ? block3.call(this, context, tags, suffix) : block6.call(this, context, tags, suffix) }`;}
function block3 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<a role="${ifDefined(this.accessibleRole)}" class="${ifDefined(this.tagClasses)}" @click="${this.tagLinkClickHandler}" href="${ifDefined(this.href)}">${ this.startIcon ? block4.call(this, context, tags, suffix) : undefined }<span class="udex-tag__text">${unsafeHTML(this.text)}</span>${ this.endIcon ? block5.call(this, context, tags, suffix) : undefined }</a>`;}
function block4 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-tag__icon udex-tag__icon--start" name="${ifDefined(this.icon)}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-tag__icon udex-tag__icon--start" name="${ifDefined(this.icon)}"></ui5-icon>`;}
function block5 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-tag__icon udex-tag__icon--end" name="${ifDefined(this.icon)}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-tag__icon udex-tag__icon--end" name="${ifDefined(this.icon)}"></ui5-icon>`;}
function block6 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.nonSelectable ? block7.call(this, context, tags, suffix) : block10.call(this, context, tags, suffix) }`;}
function block7 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="${ifDefined(this.tagClasses)}">${ this.startIcon ? block8.call(this, context, tags, suffix) : undefined }<span class="udex-tag__text">${unsafeHTML(this.text)}</span>${ this.endIcon ? block9.call(this, context, tags, suffix) : undefined }</div>`;}
function block8 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<button class="udex-tag__btn-icon" @click="${this.iconClickHandler}" role="${ifDefined(this.accessibleRole)}" aria-label="${ifDefined(this.iconAccessibleName)}"><${scopeTag("ui5-icon", tags, suffix)} class="udex-tag__icon udex-tag__icon--start" name="${ifDefined(this.icon)}"></${scopeTag("ui5-icon", tags, suffix)}></button>` : html`<button class="udex-tag__btn-icon" @click="${this.iconClickHandler}" role="${ifDefined(this.accessibleRole)}" aria-label="${ifDefined(this.iconAccessibleName)}"><ui5-icon class="udex-tag__icon udex-tag__icon--start" name="${ifDefined(this.icon)}"></ui5-icon></button>`;}
function block9 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<button class="udex-tag__btn-icon" @click="${this.iconClickHandler}" role="${ifDefined(this.accessibleRole)}" aria-label="${ifDefined(this.iconAccessibleName)}"><${scopeTag("ui5-icon", tags, suffix)} class="udex-tag__icon udex-tag__icon--end" name="${ifDefined(this.icon)}"></${scopeTag("ui5-icon", tags, suffix)}></button>` : html`<button class="udex-tag__btn-icon" @click="${this.iconClickHandler}" role="${ifDefined(this.accessibleRole)}" aria-label="${ifDefined(this.iconAccessibleName)}"><ui5-icon class="udex-tag__icon udex-tag__icon--end" name="${ifDefined(this.icon)}"></ui5-icon></button>`;}
function block10 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div tabindex="${ifDefined(this._tabIndex)}" class="${ifDefined(this.tagClasses)}" @click="${this.tagClickHandler}" @focusin="${this._focusin}" @focusout="${this._focusout}" @keydown="${this.tagKeyDown}" role="${ifDefined(this.accessibleRole)}" aria-label="${ifDefined(this.accessibleName)}" aria-pressed="${ifDefined(this.selected)}">${ this.startIcon ? block11.call(this, context, tags, suffix) : undefined }<span class="udex-tag__text">${unsafeHTML(this.text)}</span>${ this.endIcon ? block12.call(this, context, tags, suffix) : undefined }</div>`;}
function block11 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-tag__icon udex-tag__icon--start" name="${ifDefined(this.icon)}" @click="${this.iconClickHandler}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-tag__icon udex-tag__icon--start" name="${ifDefined(this.icon)}" @click="${this.iconClickHandler}"></ui5-icon>`;}
function block12 (this: Tag, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-tag__icon udex-tag__icon--end" name="${ifDefined(this.icon)}" @click="${this.iconClickHandler}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-tag__icon udex-tag__icon--end" name="${ifDefined(this.icon)}" @click="${this.iconClickHandler}"></ui5-icon>`;}


export default block0;