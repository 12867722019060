/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type TableRow from "../../TableRow.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: TableRow, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<tr class="ui5-table-row-root" tabindex="${ifDefined(this.forcedTabIndex)}" @focusin="${this._onfocusin}" @focusout="${this._onfocusout}" @click="${this._onrowclick}" @keydown="${this._onkeydown}" @keyup="${this._onkeyup}" @mouseup="${this._onmouseup}" @touchstart="${this._ontouchstart}" @touchend="${this._ontouchend}" aria-label="${ifDefined(this.ariaLabelText)}" aria-selected="${ifDefined(this.selected)}" aria-current="${ifDefined(this._ariaCurrent)}" data-sap-focus-ref part="row">${ this.isMultiSelect ? block1.call(this, context, tags, suffix) : undefined }${ this.shouldPopin ? block2.call(this, context, tags, suffix) : block4.call(this, context, tags, suffix) }<td class="ui5-table-row-navigated" aria-hidden="true"><div class="ui5-table-div-navigated"></div></td></tr>${ this.shouldPopin ? block6.call(this, context, tags, suffix) : undefined } `;}
function block1 (this: TableRow, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<td class="ui5-table-multi-select-cell" aria-hidden="true" role="presentation"><${scopeTag("ui5-checkbox", tags, suffix)} class="ui5-multi-select-checkbox" ?checked="${this.selected}" aria-label="${ifDefined(this.ariaLabelRowSelection)}" @ui5-change="${ifDefined(this._handleSelection)}" tabindex="-1"></${scopeTag("ui5-checkbox", tags, suffix)}></td>` : html`<td class="ui5-table-multi-select-cell" aria-hidden="true" role="presentation"><ui5-checkbox class="ui5-multi-select-checkbox" ?checked="${this.selected}" aria-label="${ifDefined(this.ariaLabelRowSelection)}" @ui5-change="${ifDefined(this._handleSelection)}" tabindex="-1"></ui5-checkbox></td>`;}
function block2 (this: TableRow, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ repeat(this.visibleCells, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block3.call(this, context, tags, suffix, item, index)) }`;}
function block3 (this: TableRow, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<slot name="${ifDefined(item._individualSlot)}"></slot>`;}
function block4 (this: TableRow, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ repeat(this.cells, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block5.call(this, context, tags, suffix, item, index)) }`;}
function block5 (this: TableRow, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<slot name="${ifDefined(item._individualSlot)}"></slot>`;}
function block6 (this: TableRow, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ repeat(this.popinCells, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block7.call(this, context, tags, suffix, item, index)) }`;}
function block7 (this: TableRow, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<tr part="popin-row" class="${ifDefined(item.classes)}" @click="${this._onrowclick}" @keydown="${this._onkeydown}" @keyup="${this._onkeyup}"><td colspan="${ifDefined(this.visibleCellsCount)}" role="cell">${ item.popinDisplayInline ? block8.call(this, context, tags, suffix, item, index) : block10.call(this, context, tags, suffix, item, index) }</td><td class="ui5-table-row-navigated" aria-hidden="true"><div class="ui5-table-div-navigated"></div></td></tr>`;}
function block8 (this: TableRow, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<div class="ui5-table-display-inline-container">${ item.popinText ? block9.call(this, context, tags, suffix, item, index) : undefined }<span class="ui5-table-cell-display-inline"><slot name="${ifDefined(item.cell._individualSlot)}"></slot></span></div>`;}
function block9 (this: TableRow, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<span class="ui5-table-row-popin-title">${ifDefined(item.popinText)}:</span>`;}
function block10 (this: TableRow, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`${ item.popinText ? block11.call(this, context, tags, suffix, item, index) : undefined }<div><slot name="${ifDefined(item.cell._individualSlot)}"></slot></div>`;}
function block11 (this: TableRow, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<span class="ui5-table-row-popin-title">${ifDefined(item.popinText)}:</span>`;}


export default block0;