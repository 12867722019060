import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/AllIcons.js";
import "@ui5/webcomponents-icons/dist/feeder-arrow.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";
import UDExBadge from "./Badge.js";

import AccordionItemTemplate from "./generated/templates/AccordionItemTemplate.lit.js";

// Styles
import AccordionItemCss from "./generated/themes/AccordionItem.css.js";

type ToggleAllEvent = {
  isOpen: boolean
}
/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-accordion-item</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/AccordionItem.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UI5Element
 * @tagname udex-accordion-item
 * @public
 */
@customElement({
  tag: "udex-accordion-item",
  renderer: litRender,
  styles: AccordionItemCss,
  template: AccordionItemTemplate,
  dependencies: [UDExBadge],
})

/**
 * Triggered when the user focuses on an accordion item.
 * @public
 */
@event("accordion-item-focus")

class UDExAccordionItem extends UI5Element {
  /**
   * Defines the Badge at the end of the title item.
   *
   * @default ""
   * @public
   */
  @property() numberBadge!: string;

  /**
   * Defines the title of the accordion item component.
   * TODO: With transition to UI5 2.x, change "title" attribute name.
   * Title property is also valid HTML attribute that results in showing hover tooltip and is read by screen readers.
   *
   * @default ""
   * @public
   */
  @property() title!: string;

  /**
   * Defines if the accordion item is expanded by default
   *
   * @default false
   * @public
   */
  @property({ type: Boolean }) expanded!: boolean;

  /**
   * Used internally to understand the component's open or closed state.
   *
   * @default false
   * @private
   */
  @property({ type: Boolean, noAttribute: true }) isOpen = false;

  /**
   * Defines direction of the accordion item.
   *
   * @public
   */
  @property({ type: String }) _direction?: string;

  /**
   * Slot for accordion item content.
   *
   * @slot
   * @public
   */
  @slot({ type: Node, "default": true }) content!: Array<Node>;

  _el: HTMLElement | null = null;

  constructor() {
    super();
  }

  onEnterDOM(): void {
    if (isDesktop()) {
      this.setAttribute("desktop", "");
    }
  }

  contentKeydown(e: KeyboardEvent) {
    e.stopPropagation();
  }

  itemClickHandler(e: Event) {
    e.preventDefault();
    if (this._el) {
      const isActive = this._el.classList.contains("active");
      this.toggleItemState(this._el, isActive);
      this.toggleExpanded();
    }
  }

  toggleAllHandler(e: Event) {
    e.preventDefault();
    const customEvent = e as CustomEvent<ToggleAllEvent>;
    if (this._el) { this.toggleItemState(this._el, customEvent.detail.isOpen); }
  }

  toggleItemState(el: HTMLElement, isActive: boolean) {
    if (isActive) {
      el.classList.remove("active");
      el.querySelector(".udex-accordion-item__header")?.setAttribute("aria-expanded", "false");
    } else {
      el.classList.add("active");
      el.querySelector(".udex-accordion-item__header")?.setAttribute("aria-expanded", "true");
    }
  }

  toggleExpanded(): void {
    this.expanded = !this.expanded;
  }

  focusHandler() {
    this.fireEvent("accordion-item-focus");
  }

  keydownHandler(e: KeyboardEvent) {
    switch (e.key) {
    case "Enter":
    case " ":
      this.itemClickHandler(e);
      break;
    default:
      break;
    }
  }

  get accordionItemsClass(): string {
    let classes = "udex-accordion-item";
    if (this._direction === "rtl") {
      classes = `${classes} ${this._direction}`;
    }
    return classes;
  }

  onAfterRendering() {
    this._el = this.shadowRoot?.querySelector(".udex-accordion-item") || null;
    const accordion = this.shadowRoot?.querySelector<HTMLElement>(
      ".udex-accordion-item",
    ) || null;

    if (accordion) {
      const directionStyles: string = window
        .getComputedStyle(accordion)
        .getPropertyValue("direction");
      if (!directionStyles) {
        this._direction = "ltr";
      } else {
        this._direction = directionStyles;
      }
    }

    if (this._el) {
      this.toggleItemState(this._el, !this.expanded);
    }

    this?.parentElement?.addEventListener("ui5-toggle-items", this.toggleAllHandler.bind(this));
    // This should be removed with transition to UI5 2.x, we changed "title" attribute name.
    // Fix was provided to avoid reading "title" property by screen readers, which results reading title twice.
    this.setAttribute("aria-hidden", "true");
  }
}

UDExAccordionItem.define();

export default UDExAccordionItem;
