/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type ListItem from "../../ListItem.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.modeSelected ? block1.call(this, context, tags, suffix) : undefined }${ this.modeSingleContent ? block16.call(this, context, tags, suffix) : undefined } `;}
function block1 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<li part="item" role="${ifDefined(this.roleItem)}" tabindex="${ifDefined(this._effectiveTabIndex)}" aria-disabled="${ifDefined(this._ariaDisabled)}" class="${ifDefined(this.className)}" @click="${this.handleItemClick}" @mousedown="${this.handleMouseDown}" @mouseup="${this.handleMouseUp}" @keyup="${this.handleKeyUp}" @keydown="${this.handleKeyDown}" @focusin="${this.handleFocusIn}" @focusout="${this.handleFocusOut}" aria-selected="${ifDefined(this.selected)}" aria-current="${ifDefined(this.selected)}" ?divider="${this.divider}" _id="${ifDefined(this._id)}">${ this.modeMultiSelect ? block2.call(this, context, tags, suffix) : undefined }${ this.modeRadioGroup ? block3.call(this, context, tags, suffix) : undefined }<div class="udex-list__content-wrap" _id="${ifDefined(this._id)}" aria-labelledby="${ifDefined(this._id)}-invisibleText">${ this.flag ? block4.call(this, context, tags, suffix) : undefined }${ this.typeNumber ? block5.call(this, context, tags, suffix) : undefined }${ this.typeCircle ? block6.call(this, context, tags, suffix) : undefined }${ this.typeIcon ? block7.call(this, context, tags, suffix) : undefined }${ this.image ? block8.call(this, context, tags, suffix) : undefined }<div class="udex-list__content" part="content">${ this.label ? block9.call(this, context, tags, suffix) : block11.call(this, context, tags, suffix) }</div><div class="hidden" id="${ifDefined(this._id)}-invisibleText">${ifDefined(this.accessibleName)}</div></div></li>`;}
function block2 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-checkbox", tags, suffix)} class="udex-list__checkbox" part="checkbox" tabindex="-1" ?aria-checked="${this.selected}" ?indeterminate="${this.indeterminate}" accessible-name="checkbox ${ifDefined(this.stateAccessibleName)}" ?checked="${this.selected}" ?disabled="${this.disabled}"></${scopeTag("udex-checkbox", tags, suffix)}>` : html`<udex-checkbox class="udex-list__checkbox" part="checkbox" tabindex="-1" ?aria-checked="${this.selected}" ?indeterminate="${this.indeterminate}" accessible-name="checkbox ${ifDefined(this.stateAccessibleName)}" ?checked="${this.selected}" ?disabled="${this.disabled}"></udex-checkbox>`;}
function block3 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-radio-button", tags, suffix)} class="udex-list__radio" part="radio" tabindex="-1" ?aria-checked="${this.selected}" accessible-name="radio ${ifDefined(this.stateAccessibleName)}" ?checked="${this.selected}" ?disabled="${this.disabled}"></${scopeTag("udex-radio-button", tags, suffix)}>` : html`<udex-radio-button class="udex-list__radio" part="radio" tabindex="-1" ?aria-checked="${this.selected}" accessible-name="radio ${ifDefined(this.stateAccessibleName)}" ?checked="${this.selected}" ?disabled="${this.disabled}"></udex-radio-button>`;}
function block4 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-list__flag-wrap"><span class="udex-list__flag" style="background-position: ${ifDefined(this.flagPosition)}"></span></div>`;}
function block5 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-list__number">${ifDefined(this.numberOfItem)}.</span>`;}
function block6 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-list__circle">&#x2022;</span>`;}
function block7 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-list__icon" name="${ifDefined(this._icon)}" accessible-role="presentation" aria-hidden="true" part="icon"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-list__icon" name="${ifDefined(this._icon)}" accessible-role="presentation" aria-hidden="true" part="icon"></ui5-icon>`;}
function block8 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-avatar", tags, suffix)} class="udex-list__avatar" shape="${ifDefined(this.imageShape)}" size="${ifDefined(this.imageSize)}" part="avatar"><img src="${ifDefined(this.image)}" alt="${ifDefined(this.imageAlt)}" /></${scopeTag("udex-avatar", tags, suffix)}>` : html`<udex-avatar class="udex-list__avatar" shape="${ifDefined(this.imageShape)}" size="${ifDefined(this.imageSize)}" part="avatar"><img src="${ifDefined(this.image)}" alt="${ifDefined(this.imageAlt)}" /></udex-avatar>`;}
function block9 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-list__text-holder"><span part="title">${unsafeHTML(this.label)}</span>${ this.supportingText ? block10.call(this, context, tags, suffix) : undefined }<slot part="content"></slot></div>`;}
function block10 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-list__supporting-text" part="supporting">${ifDefined(this.supportingText)}</span>`;}
function block11 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-list__text-holder"><slot part="content"></slot>${ this.supportingText ? block12.call(this, context, tags, suffix) : undefined }</div>${ this.additionalText ? block13.call(this, context, tags, suffix) : undefined }${ this.editIcon ? block14.call(this, context, tags, suffix) : undefined }${ this.deleteIcon ? block15.call(this, context, tags, suffix) : undefined }`;}
function block12 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-list__supporting-text" part="supporting">${ifDefined(this.supportingText)}</span>`;}
function block13 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-list__add-text" part="additional-text">${ifDefined(this.additionalText)}</div>`;}
function block14 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-button", tags, suffix)} @click="${this.handleEditClick}" @keydown="${this.handleKeyDownIcon}" part="edit-button" class="udex-list__edit-button" design="Tertiary" icon="${ifDefined(this.editIcon)}" accessible-name="${ifDefined(this.editButtonAccessibleName)}" toolTip="${ifDefined(this.editButtonAccessibleName)}" size="Small"></${scopeTag("udex-button", tags, suffix)}>` : html`<udex-button @click="${this.handleEditClick}" @keydown="${this.handleKeyDownIcon}" part="edit-button" class="udex-list__edit-button" design="Tertiary" icon="${ifDefined(this.editIcon)}" accessible-name="${ifDefined(this.editButtonAccessibleName)}" toolTip="${ifDefined(this.editButtonAccessibleName)}" size="Small"></udex-button>`;}
function block15 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-button", tags, suffix)} @click="${this.handleDeleteClick}" @keydown="${this.handleKeyDownIcon}" part="delete-button" class="udex-list__delete-button" design="Tertiary" size="Small" accessible-name="${ifDefined(this.deleteButtonAccessibleName)}" toolTip="${ifDefined(this.deleteButtonAccessibleName)}" icon="${ifDefined(this.deleteIcon)}"></${scopeTag("udex-button", tags, suffix)}>` : html`<udex-button @click="${this.handleDeleteClick}" @keydown="${this.handleKeyDownIcon}" part="delete-button" class="udex-list__delete-button" design="Tertiary" size="Small" accessible-name="${ifDefined(this.deleteButtonAccessibleName)}" toolTip="${ifDefined(this.deleteButtonAccessibleName)}" icon="${ifDefined(this.deleteIcon)}"></udex-button>`;}
function block16 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<li part="item" role="${ifDefined(this.roleItem)}" class="${ifDefined(this.className)}"><div class="udex-list__content-wrap" _id="${ifDefined(this._id)}" aria-labelledby="${ifDefined(this._id)}-invisibleText">${ this.flag ? block17.call(this, context, tags, suffix) : undefined }${ this.typeNumber ? block18.call(this, context, tags, suffix) : undefined }${ this.typeCircle ? block19.call(this, context, tags, suffix) : undefined }${ this.typeIcon ? block20.call(this, context, tags, suffix) : undefined }${ this.image ? block21.call(this, context, tags, suffix) : undefined }<div class="udex-list__content" part="content">${ this.label ? block22.call(this, context, tags, suffix) : block24.call(this, context, tags, suffix) }</div><div class="hidden" id="${ifDefined(this._id)}-invisibleText">${ifDefined(this.accessibleName)}</div></div></li>`;}
function block17 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-list__flag-wrap"><span class="udex-list__flag" style="background-position: ${ifDefined(this.flagPosition)}"></span></div>`;}
function block18 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-list__number">${ifDefined(this.numberOfItem)}.</span>`;}
function block19 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-list__circle">&#x2022;</span>`;}
function block20 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-list__icon" name="${ifDefined(this._icon)}" accessible-role="presentation" aria-hidden="true" part="icon"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-list__icon" name="${ifDefined(this._icon)}" accessible-role="presentation" aria-hidden="true" part="icon"></ui5-icon>`;}
function block21 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-avatar", tags, suffix)} class="udex-list__avatar" shape="${ifDefined(this.imageShape)}" size="${ifDefined(this.imageSize)}" part="avatar"><img src="${ifDefined(this.image)}" alt="${ifDefined(this.imageAlt)}" /></${scopeTag("udex-avatar", tags, suffix)}>` : html`<udex-avatar class="udex-list__avatar" shape="${ifDefined(this.imageShape)}" size="${ifDefined(this.imageSize)}" part="avatar"><img src="${ifDefined(this.image)}" alt="${ifDefined(this.imageAlt)}" /></udex-avatar>`;}
function block22 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-list__text-holder"><span part="title">${unsafeHTML(this.label)}</span>${ this.supportingText ? block23.call(this, context, tags, suffix) : undefined }<slot part="content"></slot></div>`;}
function block23 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-list__supporting-text" part="supporting">${ifDefined(this.supportingText)}</span>`;}
function block24 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-list__text-holder"><slot part="content"></slot>${ this.supportingText ? block25.call(this, context, tags, suffix) : undefined }</div>${ this.additionalText ? block26.call(this, context, tags, suffix) : undefined }${ this.editIcon ? block27.call(this, context, tags, suffix) : undefined }${ this.deleteIcon ? block28.call(this, context, tags, suffix) : undefined }`;}
function block25 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="udex-list__supporting-text" part="supporting">${ifDefined(this.supportingText)}</span>`;}
function block26 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-list__add-text" part="additional-text">${ifDefined(this.additionalText)}</div>`;}
function block27 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-button", tags, suffix)} @click="${this.handleEditClick}" @keydown="${this.handleKeyDownIcon}" part="edit-button" class="udex-list__edit-button" design="Tertiary" icon="${ifDefined(this.editIcon)}" accessible-name="${ifDefined(this.editButtonAccessibleName)}" toolTip="${ifDefined(this.editButtonAccessibleName)}" size="Small"></${scopeTag("udex-button", tags, suffix)}>` : html`<udex-button @click="${this.handleEditClick}" @keydown="${this.handleKeyDownIcon}" part="edit-button" class="udex-list__edit-button" design="Tertiary" icon="${ifDefined(this.editIcon)}" accessible-name="${ifDefined(this.editButtonAccessibleName)}" toolTip="${ifDefined(this.editButtonAccessibleName)}" size="Small"></udex-button>`;}
function block28 (this: ListItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-button", tags, suffix)} @click="${this.handleDeleteClick}" @keydown="${this.handleKeyDownIcon}" part="delete-button" class="udex-list__delete-button" design="Tertiary" size="Small" accessible-name="${ifDefined(this.deleteButtonAccessibleName)}" toolTip="${ifDefined(this.deleteButtonAccessibleName)}" icon="${ifDefined(this.deleteIcon)}"></${scopeTag("udex-button", tags, suffix)}>` : html`<udex-button @click="${this.handleDeleteClick}" @keydown="${this.handleKeyDownIcon}" part="delete-button" class="udex-list__delete-button" design="Tertiary" size="Small" accessible-name="${ifDefined(this.deleteButtonAccessibleName)}" toolTip="${ifDefined(this.deleteButtonAccessibleName)}" icon="${ifDefined(this.deleteIcon)}"></udex-button>`;}


export default block0;