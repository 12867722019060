import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import executeTemplate from "@ui5/webcomponents-base/dist/renderer/executeTemplate.js";
import "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/navigation-down-arrow.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";
import {
  isTabPrevious, isTabNext, isEscape, isSpace, isEnter,
} from "@ui5/webcomponents-base/dist/Keys.js";
import PopupAccessibleRole from "@ui5/webcomponents/dist/types/PopupAccessibleRole.js";
import ResponsivePopover from "@ui5/webcomponents/dist/ResponsivePopover.js";
import UDExList from "./List.js";
import UDExListItem from "./ListItem.js";
import UDExListGroupHeader from "./ListGroupHeader.js";
import UDExFilterMenuItem from "./FilterMenuItem.js";
import UDExFilterMenuGroupHeader from "./FilterMenuGroupHeader.js";
import UDExSearch from "./Search.js";
import UDExButton from "./Button.js";
import UDExSearchableListBase from "./SearchableListBase.js";
import { PlacementType, HorizontalAlign } from "./types/Dropdown.js";
import { ListMode } from "./types/List.js";
import UDExFilterMenuSelectAll from "./FilterMenuSelectAll.js";
import UDExListSelectAll from "./ListSelectAll.js";
import UDExBadge from "./Badge.js";
import UDExListItemBase from "./ListItemBase.js";

import { RESET, SHOW_RESULTS, SELECT_OPTIONS } from "./generated/i18n/i18n-defaults.js";
import FilterMenuTemplate from "./generated/templates/FilterMenuTemplate.lit.js";
import SearchableListBaseTemplate from "./generated/templates/SearchableListBaseTemplate.lit.js";

// Styles
import FilterMenuCss from "./generated/themes/FilterMenu.css.js";
import SearchableListCss from "./generated/themes/SearchableList.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-filter-menu</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/UDExFilterMenu.js";</code>
 *
 * @constructor
 * @extends UDExSearchableListBase
 * @tagname udex-filter-menu
 * @public
 */
@customElement({
  tag: "udex-filter-menu",
  renderer: litRender,
  styles: [FilterMenuCss, SearchableListCss],
  template: FilterMenuTemplate,
  dependencies: [
    ResponsivePopover,
    UDExFilterMenuItem,
    UDExFilterMenuGroupHeader,
    UDExFilterMenuSelectAll,
    UDExList,
    UDExListSelectAll,
    UDExListItem,
    UDExListGroupHeader,
    UDExBadge,
    UDExSearch,
    UDExButton,
  ],
})

/**
 * Fired event when the user click on a reset button on the footer.
 *
 * @event sap.ui.webc.web-components.UDExFilterMenu#resetClick
 * @public
 */
@event("reset-click", {
  detail: {
    restoredItems: { type: Array },
  },
})

/**
 * Fired event when the user click on a result button on the footer.
 *
 * @event sap.ui.webc.web-components.UDExFilterMenu#resultClick
 * @public
 */
@event("result-click", {
  detail: {
    selectedItems: { type: Array },
  },
})

/**
 * Fired event when the user click on Select All header item.
 *
 * @event sap.ui.webc.web-components.UDExFilterMenu#selectAll
 * @public
 */
@event("select-all")

/**
 * Fired before the dropdown pane is opened.
 *
 * @event sap.ui.webc.web-components.UDExFilterMenu#beforeOpen
 * @public
 */
@event("before-open")

/**
 * Fired after the dropdown pane is opened.
 *
 * @event sap.ui.webc.web-components.UDExFilterMenu#afterOpen
 * @public
 */
@event("after-open")

/**
 * Fired before the dropdown pane is closed.
 *
 * @event sap.ui.webc.web-components.UDExFilterMenu#beforeClose
 * @public
 */
@event("before-close")

/**
 * Fired after the dropdown pane is closed.
 *
 * @event sap.ui.webc.web-components.UDExFilterMenu#afterClose
 * @public
 */
@event("after-close")

class UDExFilterMenu extends UDExSearchableListBase {
  /**
   * Determines the value of the badge.
   *
   * @public
   */
  @property({ validator: Integer })
    badgeValue?: number;

  /**
   * Determines if there is no enough space, the component can be placed
   * over the target.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    disableTargetOverlap?: boolean;

  /**
   * Allows control over the height.
   * @public
   */
  @property({ type: String })
    height?: string;

  /**
   * Determines on which side the component is placed at.
   *
   * @default "Bottom"
   * @public
   */
  @property({ type: PlacementType, defaultValue: PlacementType.Bottom })
    placementType!: `${PlacementType}`;

  /**
   * Determines the horizontal alignment of the component.
   *
   * @default "Left"
   * @public
   */
  @property({ type: HorizontalAlign, defaultValue: HorizontalAlign.Left })
    horizontalAlign!: `${HorizontalAlign}`;

  /**
   * Defines the mode of the component.
   *
   * @default "SingleSelect"
   * @public
   */
  @property({ type: ListMode, defaultValue: ListMode.SingleSelect })
    mode!: `${ListMode}`;

  /**
   * Defines the label of the button reset in the footer.
   *
   * @default "Reset"
   * @public
   */
  @property()
    resetBtnLabel!: string;

  /**
   * Defines the label of the button result in the footer.
   *
   * @default "Show Result"
   * @public
   */
  @property()
    resultBtnLabel!: string;

  /**
   * Defines the filter name of the component.
   *
   * @default ""
   * @public
   */
  @property()
    filterName!: string;

  /**
   * Defines the filter name prefix of the component.
   *
   * @default ""
   * @public
   */
  @property()
    filterNamePrefix!: string;

  /**
   * Defines whether the component is in disabled state.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    disabled!: boolean;

  /**
   * Defines whether the dropdown is open or not.
   *
   * @default false
   * @private
   */
  @property({ type: Boolean })
    _open!: boolean;

  /**
   * Hides the result button in the footer.
   *
   * @type {boolean}
   * @name sap.ui.webc.web-components.UDExFilterMenu.prototype.hideResultBtn
   * @defaultvalue false
   * @public
   */
  @property({ type: Boolean })
    hideResultBtn!: boolean;

  /**
   * Hides the reset button in the footer.
   *
   * @type {boolean}
   * @name sap.ui.webc.web-components.UDExFilterMenu.prototype.hideResetBtn
   * @defaultvalue false
   * @public
   */
  @property({ type: Boolean })
    hideResetBtn!: boolean;

  /**
   * Defines the accessible ARIA name of the component.
   *
   * @default ""
   * @public
   */
  @property()
    accessibleName!: string;

  /**
   * Allows setting a custom role.
   * @default "None"
   * @public
   */
  @property({ type: PopupAccessibleRole, defaultValue: PopupAccessibleRole.None })
    accessibleRole!: `${PopupAccessibleRole}`;

  /**
   * Allows setting a custom role for dropdown trigger.
   * @default "combobox"
   * @public
   */
  @property({ type: String, defaultValue: "combobox" })
    triggerAccessibleRole!: string;

  _trigger!: UDExButton;
  _search!: UDExSearch;
  _hasFooterButtons!: boolean;
  _popover!: ResponsivePopover;
  _breakpointSizeXS = 640;

  onEnterDOM(): void {
    if (isDesktop()) {
      this.setAttribute("desktop", "");
    }
  }

  onBeforeRendering() {
    super.onBeforeRendering();
  }

  onAfterRendering() {
    this._search = this.getSearch();
    this._popover = this.getPopover();
    this._trigger = this.getTriggerButton();
    this._hasFooterButtons = this.hasFooterButtons;
  }

  handleSelectAll(e:CustomEvent): void {
    this.onFilterSelectAllHeaderPress(e);
    this.fireEvent("select-all");
  }

  handleItemPress(e:CustomEvent): void {
    this.onItemPress(e);

    if (this.modeSingleSelect) {
      this.onDropdownClose();
    }
  }

  handleHeaderPress(e:CustomEvent): void {
    this.onHeaderPress(e);
  }

  handleValueSearch(e:CustomEvent): void {
    this.onSearch(e);
  }

  handleSubmitSearch(): void {
    this.onDropdownClose();
    this.onSubmitSearch();
  }

  handleResetSearch(): void {
    this.onResetSearch();
  }

  handleReset(): void {
    this.fireEvent("reset-click", {
      restoredItems: this.selectedItems,
    });
    this.onReset();
  }

  handleResult(): void {
    if (!this.disabledResultBtn) {
      this._popover.close();
      this.fireEvent("result-click", {
        selectedItems: this.selectedItems,
      });
    }
  }

  handleKeyDownList(e: KeyboardEvent): void {
    const isTabNextPress = isTabNext(e) && !this._hasFooterButtons;
    const isTabPrevPress = isTabPrevious(e) && !this._search;
    const target = e.target as HTMLElement;
    const isSearchResetTrigger = target === this._search;
    if ((isTabNextPress && (!isSearchResetTrigger || this.isListEmpty)) || isTabPrevPress) {
      this.onDropdownClose(e);
    }
  }

  handleKeyDownSearch(e: CustomEvent): void {
    const keyEvent = e.detail as KeyboardEvent;
    if (isTabPrevious(keyEvent) || isEscape(keyEvent)) {
      this.onDropdownClose(keyEvent);
    }
  }

  handleKeyDownResultBtn(e: KeyboardEvent): void {
    e.stopPropagation();
    if (isTabNext(e) && !this.hasResetBtn) {
      this.onDropdownClose();
    }
  }

  handleKeyDownResetBtn(e: KeyboardEvent): void {
    e.stopPropagation();
    if (isTabNext(e)) {
      this.onDropdownClose();
    }
  }

  handleTriggerClick(): void {
    this.toggleDropdown();
  }

  handleTriggerKeydown(e: KeyboardEvent): void {
    if (isSpace(e) || isEnter(e)) {
      e.preventDefault();
      this.toggleDropdown();
    }
  }

  toggleDropdown(): void {
    this._open = !this._open;

    if (this._open) {
      this._trigger.classList.add("udex-filter-menu__trigger-active");
      this._popover.showAt(this._trigger);
    } else {
      this.onDropdownClose();
    }
  }

  onDropdownClose(e?: KeyboardEvent): void {
    if (this._popover.open) {
      if (e && (isTabPrevious(e) || isEscape(e))) {
        e.preventDefault();
        this._popover.close(true);
      } else {
        this._popover.close();
      }
    }
  }

  getSearch(): UDExSearch {
    return this.shadowRoot!.querySelector<UDExSearch>("udex-search")!;
  }

  _dropdownBeforeOpen(): void {
    if (this.height) {
      const content = this._popover.shadowRoot!.querySelector("[part=content]") as HTMLElement;
      content.style.maxHeight = this.height;
    }
    this.fireEvent("before-open");
  }

  _dropdownAfterOpen(): void {
    const popoverLeftOffset = +this._popover.style.left.split("px")[0];
    if (this._popover.offsetWidth + popoverLeftOffset < this._breakpointSizeXS) {
      this.style.setProperty("--udex-filter-menu-popover-left-offset", `${this._popover.style.left}`);
    }
    this.fireEvent("after-open");
  }

  _dropdownBeforeClose(): void {
    this.fireEvent("before-close");
  }

  _dropdownAfterClose(): void {
    this._open = false;
    this._trigger.classList.remove("udex-filter-menu__trigger-active");
    this.fireEvent("after-close");
  }

  get disableAllowTargetOverlap(): boolean {
    return !this.disableTargetOverlap;
  }

  get hasFilterNamePrefix(): boolean {
    return !!this.filterNamePrefix?.length;
  }

  get getResultLabelText(): string {
    const hasLabel = this.resultBtnLabel !== "undefined" && this.resultBtnLabel.length > 0;
    return hasLabel ? this.resultBtnLabel : UDExFilterMenu.i18nBundle.getText(SHOW_RESULTS);
  }

  get getResetLabelText(): string {
    const hasLabel = this.resetBtnLabel !== "undefined" && this.resetBtnLabel.length > 0;
    return hasLabel ? this.resetBtnLabel : UDExFilterMenu.i18nBundle.getText(RESET);
  }

  get disabledResultBtn(): boolean {
    return !this.selectedItems.length && !this.selectAll;
  }

  get _iconAccessibleNameText(): string {
    return UDExFilterMenu.i18nBundle.getText(SELECT_OPTIONS);
  }

  get triggerButtonAccessibleName(): string {
    return this.accessibleName || UDExFilterMenu.i18nBundle.getText(SELECT_OPTIONS);
  }

  get searchableList() {
    return executeTemplate(UDExFilterMenu.searchableListBaseTemplate, this);
  }

  get hideActionBar(): boolean {
    return this.hideResultBtn && this.hideResetBtn;
  }

  get hasFooterButtons(): boolean {
    return (this.hasResultBtn || this.hasResetBtn) && this.modeMultiSelect;
  }

  get hasResultBtn(): boolean {
    return !this.hideResultBtn && !this.disabledResultBtn;
  }

  get hasResetBtn(): boolean {
    return !this.hideResetBtn;
  }

  getTriggerButton(): UDExButton {
    return this.shadowRoot!.querySelector<UDExButton>("#filterMenuTrigger")!;
  }

  getPopover(): ResponsivePopover {
    return this.shadowRoot!.querySelector<ResponsivePopover>("[ui5-popover]")!;
  }

  getItems(): Array<UDExListItemBase> {
    return this.getSlottedNodes<UDExListItemBase>("items");
  }

  static get searchableListBaseTemplate() {
    return SearchableListBaseTemplate;
  }
}

UDExFilterMenu.define();

export default UDExFilterMenu;
