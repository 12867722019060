import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import Avatar from "@ui5/webcomponents/dist/Avatar.js";
import I18nBundle, { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import { ATTENTION_BADGE, AVATAR_TEXT, DISABLED } from "./generated/i18n/i18n-defaults.js";

// Template
import AvatarTemplate from "./generated/templates/AvatarTemplate.lit.js";

// Styles
import AvatarCss from "./generated/themes/Avatar.css";

export enum AvatarColorScheme {
  Mango = "Mango",
  Red = "Red",
  Raspberry = "Raspberry",
  Pink = "Pink",
  Indigo = "Indigo",
  Blue = "Blue",
  Teal = "Teal",
  Green = "Green",
  Grey = "Grey",
  Transparent = "Transparent",
  Neutral = "Neutral",
}

export enum AvatarShape {
  Circle = "Circle",
  Square = "Square",
}

export enum AvatarSize {
  XS = "XS",
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
}

const udexToUi5ColorSchemeMap = {
  Mango: "Accent1",
  Red: "Accent2",
  Raspberry: "Accent3",
  Pink: "Accent4",
  Indigo: "Accent5",
  Blue: "Accent6",
  Teal: "Accent7",
  Green: "Accent8",
  Grey: "Accent10",
  Transparent: "Accent6",
  Neutral: "Accent6",
};

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-avatar</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/Avatar.js";</code>
 *
 * @constructor
 * @extends UI5Element
 * @public
 */
@customElement({
  tag: "udex-avatar",
  renderer: litRender,
  styles: AvatarCss,
  template: AvatarTemplate,
  dependencies: [Avatar],
})
class UDExAvatar extends UI5Element {
  /**
   * Defines the displayed initials.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    initials!: string;

  /**
   * Defines if the avatar is interactive (focusable and pressable).
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    interactive!: boolean;

  /**
   * Defines the text alternative of the component.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    accessibleName!: string;

  /**
   * Defines the design of the component.
   *
   * @default "Blue"
   * @public
   */
  @property({ type: AvatarColorScheme, defaultValue: AvatarColorScheme.Blue })
    colorScheme!: `${AvatarColorScheme}`;

  /**
   * Defines whether the component is disabled.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    disabled!: boolean;

  /**
   * Defines the name of the fallback icon.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    fallbackIcon!: string;

  /**
   * Defines the name of the UI5 Icon, that will be displayed.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    icon!: string;

  /**
   * Defines the shape of the component.
   *
   * @default "Circle"
   * @public
   */
  @property({ type: AvatarShape, defaultValue: AvatarShape.Circle })
    shape!: `${AvatarShape}`;

  /**
   * Defines predefined size of the component.
   *
   * @default "S"
   * @public
   */
  @property({ type: AvatarSize, defaultValue: AvatarSize.S })
    size!: `${AvatarSize}`;

  /**
   * Defines the displaying attention badge.
   *
   * @public
   * @default false
   */
    @property({ type: Boolean })
      showAttentionBadge!: boolean;

  /**
   * Receives the desired <img> tag
   *
   * @slot image
   * @public
   */
  @slot({ type: HTMLElement, "default": true })
    image!: Array<HTMLElement>;

  /**
   * Defines the optional badge that will be used for visual affordance.
   *
   * @slot
   * @public
   */
  @slot({ type: HTMLElement })
    badge!: Array<HTMLElement>;

  static i18nBundle: I18nBundle;

  static async onDefine(): Promise<void> {
    UDExAvatar.i18nBundle = await getI18nBundle("@udex/web-components");
  }

  handleClick(e: Event) {
    e.stopPropagation();
    if (this.interactive) {
      this.fireEvent("click");
    }
  }

  /**
   * Returns the effective avatar size.
   * @default "S"
   * @private
   */
  get effectiveSize(): AvatarSize {
    // we read the attribute, because the "size" property will always have a default value
    return this.getAttribute("size") as AvatarSize || this.size;
  }

  /**
   * Returns the effective background color.
   * @default "Blue"
   * @private
   */
  get еffectiveBackgroundColor(): AvatarColorScheme {
    // we read the attribute, because the "background-color" property will always have a default value
    return this.getAttribute("color-scheme") as AvatarColorScheme || this.colorScheme;
  }

  get getUI5AvatarColorScheme(): string {
    return udexToUi5ColorSchemeMap[this.colorScheme];
  }

  get imageSlot(): boolean {
    return !!this.image.length;
  }

  get badgeSlot(): boolean {
    return !!this.badge.length;
  }

  get udexAvatarClass(): string {
    let avatarClass = "udex-avatar";

    if (this.imageSlot) {
      avatarClass += " udex-avatar--with-image";
    }

    return avatarClass;
  }

  get avatarAccessibleName(): string {
    let accName = `${this.accessibleName ? this.accessibleName : `${UDExAvatar.i18nBundle.getText(AVATAR_TEXT)} ${this.initials}`}`;
    accName += ` ${this.disabled ? UDExAvatar.i18nBundle.getText(DISABLED) : ""}`;
    accName += ` ${this.showAttentionBadge ? UDExAvatar.i18nBundle.getText(ATTENTION_BADGE) : ""}`;
    return accName;
  }
}

UDExAvatar.define();

export default UDExAvatar;
