import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import { isEnter, isSpace } from "@ui5/webcomponents-base/dist/Keys.js";
import UDExListGroupHeaderBase from "./ListGroupHeaderBase.js";
import { ListMode } from "./types/List.js";
import { SELECT_ALL } from "./generated/i18n/i18n-defaults.js";
import ListSelectAllTemplate from "./generated/templates/ListSelectAllTemplate.lit";

// Styles
import ListGroupHeaderCss from "./generated/themes/ListGroupHeader.css";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-list-select-all</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/ListSelectAll.js";</code>
 *
 * @constructor
 * @extends UDExListGroupHeaderBase
 * @public
 */
@customElement({
  tag: "udex-list-select-all",
  renderer: litRender,
  styles: ListGroupHeaderCss,
  template: ListSelectAllTemplate,
})

@event("select-all", {
  detail: {
    item: { type: HTMLElement },
  },
})

class UDExListSelectAll extends UDExListGroupHeaderBase {
  static i18nBundle: I18nBundle;

  static async onDefine(): Promise<void> {
    UDExListSelectAll.i18nBundle = await getI18nBundle("@udex/web-components");
  }

  handleSelectAllPress() {
    if (!this.controlled) {
      this.handleSelectionGroupState();
    }
    this.fireEvent("select-all", {
      item: this,
    });
  }

  handleSelectAllGroupItemsPress() {
    if (!this.controlled) {
      this.handleHeaderState();
    }
  }

  handleKeyDownPress(e: KeyboardEvent) {
    if (isSpace(e)) {
      e.preventDefault();
    }
    if (isSpace(e) || isEnter(e)) {
      this.handleSelectAllPress();
    }
  }

  get isSelectAllHeader() {
    return true;
  }

  get multiselectMode(): ListMode {
    return ListMode.MultiSelect;
  }

  get selectAllLabel(): string {
    const hasLabel = this.label !== "undefined" && this.label?.length > 0;
    return hasLabel ? this.label : UDExListSelectAll.i18nBundle.getText(SELECT_ALL);
  }

  get additionalClassName(): string {
    const selectAllClassName = "udex-list__header-select-all";
    return `${selectAllClassName} ${this._additionalClassName}`;
  }

  get groupListClassName(): string {
    const className = "udex-list__group-list";
    const selectAllClassName = "udex--list__header-select-all";
    return `${className} ${this._additionalClassName} ${selectAllClassName}`;
  }
}

UDExListSelectAll.define();

export default UDExListSelectAll;
