import type { StyleData } from "@ui5/webcomponents-base/dist/types.js";
import { registerThemePropertiesLoader } from "@ui5/webcomponents-base/dist/asset-registries/Themes.js";

import defaultThemeBase from "@ui5/webcomponents-theming/dist/generated/themes/sap_horizon/parameters-bundle.css.js";
import defaultTheme from "./sap_horizon/parameters-bundle.css.js";

registerThemePropertiesLoader("@ui5/webcomponents-theming", "sap_horizon", async () => defaultThemeBase);
registerThemePropertiesLoader("@sapudex/web-components", "sap_horizon", async () => defaultTheme);

const styleData: StyleData = {packageName:"@sapudex/web-components",fileName:"themes/RadioButton.css.ts",content:`:host{--udex-radio-group-color-display-only-background: var(--udexColorNeutralWhite0, #ffffff00);--udex-radio-group-color-display-only-border: var(--sapField_TextColor, #223548);--udex-radio-group-color-display-only-selection: var(--sapField_TextColor, #223548)}:host([display-only]) .ui5-radio-root .ui5-radio-svg-outer{stroke-dasharray:none;fill:var(--udex-radio-group-color-display-only-background);stroke:var(--udex-radio-group-color-display-only-border)}:host([display-only][checked]) .ui5-radio-root .ui5-radio-svg-inner{fill:var(--udex-radio-group-color-display-only-selection)}:host([display-only][disabled]) .ui5-radio-root .ui5-radio-svg-outer{stroke:var(--sapField_BorderColor, #5b738b)}:host([display-only][checked][disabled]) .ui5-radio-root .ui5-radio-svg-inner{fill:var(--sapContent_Selected_ForegroundColor, #0070f2)}:host([value-state="Success"]){--sapField_SuccessColor: var(--udexCoreSemanticSuccess5, #5DC122)}:host([value-state="Error"]){--sapField_InvalidColor: var(--udexCoreSemanticError5, #FF5C77)}
`};
export default styleData;
	