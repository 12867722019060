/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type AccordionItem from "../../AccordionItem.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: AccordionItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<li class=${ifDefined(this.accordionItemsClass)} aria-expanded="${ifDefined(this.expanded)}" role="listitem" @keydown=${this.keydownHandler}><div class="udex-accordion-item__header-wrapper"><div role="button" class="udex-accordion-item__header" tabindex="0" aria-expanded="false" aria-controls="udex-accordion-item__${ifDefined(this._id)}-content" @focus=${this.focusHandler} @click=${this.itemClickHandler}><div class="udex-accordion-item__icon-wrapper"><${scopeTag("ui5-icon", tags, suffix)} class="udex-accordion-item__icon" name="slim-arrow-right"></${scopeTag("ui5-icon", tags, suffix)}></div><span class="udex-accordion-item__title">${ifDefined(this.title)}${ this.numberBadge ? block1.call(this, context, tags, suffix) : undefined }</span></div></div><div id="udex-accordion-item__${ifDefined(this._id)}-content" @keydown=${this.contentKeydown} class="udex-accordion-item__content"><slot></slot></div></li>` : html`<li class=${ifDefined(this.accordionItemsClass)} aria-expanded="${ifDefined(this.expanded)}" role="listitem" @keydown=${this.keydownHandler}><div class="udex-accordion-item__header-wrapper"><div role="button" class="udex-accordion-item__header" tabindex="0" aria-expanded="false" aria-controls="udex-accordion-item__${ifDefined(this._id)}-content" @focus=${this.focusHandler} @click=${this.itemClickHandler}><div class="udex-accordion-item__icon-wrapper"><ui5-icon class="udex-accordion-item__icon" name="slim-arrow-right"></ui5-icon></div><span class="udex-accordion-item__title">${ifDefined(this.title)}${ this.numberBadge ? block1.call(this, context, tags, suffix) : undefined }</span></div></div><div id="udex-accordion-item__${ifDefined(this._id)}-content" @keydown=${this.contentKeydown} class="udex-accordion-item__content"><slot></slot></div></li>`;}
function block1 (this: AccordionItem, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-badge", tags, suffix)} class="udex-accordion-item__badge" type="Number" number-design="Blue" size="Large">${ifDefined(this.numberBadge)}</${scopeTag("udex-badge", tags, suffix)}>` : html`<udex-badge class="udex-accordion-item__badge" type="Number" number-design="Blue" size="Large">${ifDefined(this.numberBadge)}</udex-badge>`;}


export default block0;