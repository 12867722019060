import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Icon from "@ui5/webcomponents/dist/Icon.js";
import { isEnter } from "@ui5/webcomponents-base/dist/Keys.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";

import TagTemplate from "./generated/templates/TagTemplate.lit.js";

// Styles
import TagCss from "./generated/themes/Tag.css";

export enum TagType {
    Text = "Text",
    White = "White",
    Outlined = "Outlined",
    Grey = "Grey"
}

export enum IconPlacement {
    Start = "Start",
    End = "End"
}

export enum TagSize {
  Small = "Small",
  Medium = "Medium"
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-tag</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/Tag.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UI5Element
 * @tagname udex-tag
 * @public
 */
@customElement({
  tag: "udex-tag",
  renderer: litRender,
  styles: TagCss,
  template: TagTemplate,
  dependencies: [Icon],
})

/**
 * Emitted when tag was clicked
 *
 * @event sap.ui.webc.web-components.UDExTag#selectionChange
 * @public
 */
@event("selectionChange")

/**
 * Emitted when icon in tag was clicked
 *
 * @event sap.ui.webc.web-components.UDExTag#iconClick
 * @public
 */
@event("iconClick")
/**
 * Emitted when tag link was clicked
 *
 * @public
 */
@event("tagLinkClick")
class UDExTag extends UI5Element {
  /**
   * Defines the text of the tag
  *
  * @default ""
  * @public
  */
  @property()
    text!: string;
  /**
   * Defines the href for behavior of the tag as a link
   *
   * @default ""
   * @public
   */
  @property()
    href!: string;
  /**
   * Defines the design type of tag
  *
  * @default "Grey"
  * @public
  */
  @property({ type: TagType, defaultValue: TagType.Grey })
    design?: `${TagType}`;

  /**
   * Defines the size of the component.
   *
   * @default "Small"
   * @public
   */
  @property({ type: TagSize, defaultValue: TagSize.Small })
    size!: `${TagSize}`;

  /**
   * Defines icon name
  *
  * @default ""
  * @public
  */
  @property()
    icon!: string;

  /**
   * Defines icon placement if icon was provided
  *
  * @default "End"
  * @public
  */
  @property({ type: IconPlacement, defaultValue: IconPlacement.End })
    iconPlacement? : `${IconPlacement}`;

  /**
   * Defines if tag is in readnly state
  *
  * @default false
  * @public
  */
  @property({ type: Boolean })
    readOnly!: boolean;

  /**
   * Defines the accessible ARIA name of the component.
   * @default ""
   * @public
   */
  @property()
    accessibleName!: string;

  /**
   * Defines the role attribute of component
  *
  * @default "button"
  * @public
  */
  @property({ defaultValue: "button" })
    accessibleRole!: string;

  /**
   * Defines if tag is selected
  *
  * @default false
  * @public
  */
  @property({ type: Boolean })
    selected!: boolean;

  /**
   * Defines whether the component is focused or not.
   *
   * @default false
   * @private
   */
  @property({ type: Boolean })
    focused!: boolean;

  /**
   * Defines whether the token is being deleted
   * This flag is used in the udex-select-box
   *
   * @default false
   * @private
   */
  @property({ type: Boolean })
    toBeDeleted!: boolean;

  /**
   * Set by the tokenizer when a token is in the "more" area (overflowing)
   *
   * @default false
   * @private
   */
  @property({ type: Boolean })
    overflows!: boolean;

  @property({ type: Boolean })
    singleToken!: boolean;

  /**
  * Toggle on/off nonSelectable property on tag
  * @default true
  * @private
  */
  @property({ type: Boolean })
    nonSelectable!: boolean;

  onBeforeRendering(): void {
    this.toBeDeleted = false;
  }

  onEnterDOM(): void {
    if (isDesktop()) {
      this.setAttribute("desktop", "");
    }
    if (this.accessibleRole !== "button") {
      this.shadowRoot?.firstElementChild?.removeAttribute("aria-pressed");
    }
  }

  setSelected(): void {
    this.selected = !this.selected;
  }

  iconClickHandler(e: Event): void {
    e.stopPropagation();
    this.fireEvent("iconClick");
  }

  tagClickHandler(): void {
    if (this.isInteractiveTag) {
      this.setSelected();
      this.fireEvent("selectionChange");
    }
  }

  tagKeyDown(e: KeyboardEvent): void {
    if (isEnter(e)) {
      this.tagClickHandler();
    }
  }

  _focusin(): void {
    this.focused = true;
  }

  _focusout(): void {
    this.focused = !this.focused;
  }

  get tagClasses(): string {
    const hasIconClass = this.hasIcon ? "udex-tag--has-icon" : "";
    const readOnlyClass = this.readOnly ? "udex-tag--readonly" : "";
    const nonSelectableClass = this.nonSelectable && !this.href ? "udex-tag--nonselectable" : "";
    const linkTagClass = this.href ? "udex-tag--link" : "";
    const selectableClass = this.selected ? "udex-tag--selected" : "";
    const designClass = `udex-tag-${this.design?.toLowerCase()}`;
    return `udex-tag udex-tag--${this.size.toLocaleLowerCase()} ${designClass} ${hasIconClass} ${readOnlyClass} ${nonSelectableClass} ${linkTagClass} ${selectableClass}`.trim();
  }

  get startIcon(): boolean {
    return this.hasIcon && this.iconPlacement === IconPlacement.Start;
  }

  get endIcon(): boolean {
    return this.hasIcon && this.iconPlacement === IconPlacement.End;
  }

  get _tabIndex(): number {
    if (this.overflows || this.nonSelectable) {
      return -1;
    }
    return 0;
  }

  get textDom(): HTMLElement {
    return this.getDomRef()!.querySelector(".udex-tag__text")!;
  }

  get isTruncatable(): boolean {
    if (!this.textDom) {
      return false;
    }

    return Math.ceil(this.textDom.getBoundingClientRect().width) < Math.ceil(this.textDom.scrollWidth);
  }

  get isInteractiveTag(): boolean {
    return !this.readOnly && !this.toBeDeleted && !this.nonSelectable;
  }

  get hasIcon(): boolean {
    return !!this.icon && !this.readOnly;
  }

  get iconAccessibleName(): string {
    return this.accessibleName || this.text;
  }

  tagLinkClickHandler(e: Event): void {
    this.fireEvent("tagLinkClick", { originalEvent: e }, true);
  }
}

UDExTag.define();

export default UDExTag;
