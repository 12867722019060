import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import Search from "./Search.js";
import UDExListItemBase from "./ListItemBase.js";
import UDExListGroupHeaderBase from "./ListGroupHeaderBase.js";
import { ListMode } from "./types/List.js";
import UDExFilterMenuItem from "./FilterMenuItem.js";
import { ProcessedItem, SHOW_SEARCH_AFTER_COUNT } from "./types/SearchableListBase.js";
import { NO_MATCHES_FOUND } from "./generated/i18n/i18n-defaults.js";

/**
 * @class
 *
 * A class to serve as a foundation
 * for the <code>UDExFilterMenu</code> , <code>UDExSelectBox</code> and <code>UDExCountrySelector/code> classes.
 *
 * @constructor
 * @author SAP SE
 * @extends UI5Element
 * @public
 */

/**
 * Fired event when the user click on an item on the list.
 *
 * @event sap.ui.webc.web-components.UDExSearchableListBase#selectionChange
 * @public
 */
@event("selection-change", {
  detail: {
    selectedItems: { type: Array },
    item: { type: HTMLElement },
  },
})

/**
 * Fired event when the user clicks on the Select All header.
 *
 * @event sap.ui.webc.web-components.UDExSearchableListBase#selectAll
 * @public
 */
@event("select-all")

/**
 * Fired event when the user clicks on the submitted icon in the search.
 *
 * @event sap.ui.webc.web-components.UDExSearchableListBase#submitSearch
 * @public
 */
@event("submit-search", {
  detail: {
    selectedItems: { type: Array },
  },
})

/**
 * Fired event when the user clicks on the reset button in the search.
 *
 * @event sap.ui.webc.web-components.UDExSearchableListBase#resetSearch
 * @public
 */
@event("reset-search")

/**
 * Fired event when the user submitted the value in the search.
 *
 * @event sap.ui.webc.web-components.UDExSearchableListBase#changeSearch
 * @public
 */
@event("change-search", {
  detail: {
    value: { type: String },
  },
})

class UDExSearchableListBase extends UI5Element {
  /**
   * Defines the "no result" text of the component.
   *
   * @default ""
   * @public
   */
  @property()
    noResultText!: string;

  /**
   * Defines the placeholder of the search.
   *
   * @default ""
   * @public
   */
  @property()
    searchPlaceholder!: string;

  /**
   * Defines the selectAll option of the component.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    selectAll?: boolean;

  /**
   * Defines label text for select all header.
   *
   * @default "Select All"
   * @public
   */
  @property()
    selectAllLabel!: string;

  /**
   * Defines the mode of the component.
   *
   * @default "SingleSelect"
   * @public
   */
  @property({ type: ListMode, defaultValue: ListMode.SingleSelect })
    mode!: `${ListMode}`;

  /**
   * Slot for items.
   *
   * @slot
   * @public
   */
  @slot({ type: HTMLElement, "default": true, invalidateOnChildChange: true })
    items!: Array<UDExListItemBase | UDExListGroupHeaderBase>;

  @property({ type: Object })
    _filteredItems!: Array<ProcessedItem>;

  _shouldFilterItems?: boolean;

  static i18nBundle: I18nBundle;

  constructor() {
    super();
    this._shouldFilterItems = false;
    this._filteredItems = [];
  }

  onBeforeRendering() {
    if (!this._shouldFilterItems) {
      this._filteredItems = this.getProcessedItems();
    }
  }

  onItemPress(e:CustomEvent) {
    const dropdownItemId: string = e.detail.item.getAttribute("data-token-id");
    this.handleDropdownItemPress(dropdownItemId);
    this.fireSelectionChange(dropdownItemId);
  }

  onFilterSelectAllHeaderPress(e:CustomEvent) {
    const dropdownHeaderItemId = this.getDataTokenId(e);
    this.getProcessedItems().forEach(({ item }) => {
      if (item._id === dropdownHeaderItemId) {
        this.activateHeaderAndSetAllItems(item as UDExListGroupHeaderBase);
      }
    });

    this.syncFilteredItems();
    this.fireSelectionChange(dropdownHeaderItemId);
  }

  activateHeaderAndSetAllItems(item: UDExListGroupHeaderBase) {
    item.activateHeader();
    item.setAllItems(false);
  }

  handleDropdownItemPress(dropdownItemId: string) {
    this.getProcessedItems().forEach(({ item, groupItems, isGroup }) => {
      if (isGroup) {
        const groupItem: UDExListItemBase = groupItems.find(({ _id }) => _id === dropdownItemId)!;
        if (groupItem) {
          this.handleItemClick(groupItem);
          this.handleGroupHeaderState(item as UDExListGroupHeaderBase);
        }
      } else if (item._id === dropdownItemId) {
        this.handleItemClick(item);
      }
    });

    this.syncFilteredItems();
  }

  handleItemClick(item: UDExListItemBase) {
    if (this.modeSingleSelect) {
      this.revertSelectedItems(this.selectedItems);
    }

    if (this.modeRadioGroup) {
      const groupHeader = item.parentElement as UDExListGroupHeaderBase;
      this.revertSelectedItems(groupHeader.getItems());
    }

    item.selected = !item.selected;
  }

  handleGroupHeaderState(groupHeader: UDExListGroupHeaderBase) {
    if (this.modeMultiSelect) {
      if (groupHeader.isFilterMenuSelectAllHeader) {
        groupHeader.deactivateHeader();

        if (groupHeader.isAllDeselected()) {
          groupHeader.activateHeader();
        }
      } else {
        groupHeader.handleHeaderState();
      }
    }
  }

  onHeaderPress(e:CustomEvent) {
    const dropdownHeaderItemId = this.getDataTokenId(e);
    this.getProcessedItems().forEach(({ item }) => {
      if (item._id === dropdownHeaderItemId) {
        (item as UDExListGroupHeaderBase).handleSelectionGroupState();
      }
    });

    this.syncFilteredItems();
    this.fireSelectionChange(dropdownHeaderItemId);
  }

  onSearch(e:CustomEvent) {
    const searchValue: string = e.detail.value;
    this._shouldFilterItems = true;
    this._filteredItems = this.filterItems(this.getProcessedItems(), searchValue);
    this.fireEvent("change-search", { value: searchValue });
  }

  onReset() {
    this.revertSelectedItems(this.selectedItems);
  }

  onResetSearch() {
    this.resetSearch();
    this.fireEvent("reset-search");
  }

  onSubmitSearch() {
    this.fireEvent("submit-search", {
      selectedItems: this.selectedItems,
    });
  }

  /**
   * Reset the search value.
   * @public
   */
  resetSearch() {
    const search = this.getSearch();
    search.value = "";
    this._filteredItems = this.filterItems(this.getProcessedItems(), "");
  }

  filterItems(items: Array<ProcessedItem>, str: string): Array<ProcessedItem> {
    return items.filter(preparedItem => {
      const { isGroup, item } = preparedItem;
      if (isGroup) {
        preparedItem.groupItems = this.filterItemsForLabel(preparedItem.groupItems, str);
        return preparedItem.groupItems.length > 0;
      }
      return this.checkAndUpdateLabel(item, str);
    });
  }

  filterItemsForLabel(groupItems: Array<UDExListItemBase>, str: string): Array<UDExListItemBase> {
    return groupItems.filter(groupItem => this.checkAndUpdateLabel(groupItem, str));
  }

  checkAndUpdateLabel(item: UDExListItemBase | UDExListGroupHeaderBase, str: string): boolean {
    const cleanedLabel = this.cleanLabel(item.label);
    if (this.isIncludes(cleanedLabel, str)) {
      item.label = this.addSearchTermTag(cleanedLabel, str);
      return true;
    }
    return false;
  }

  cleanLabel(str: string): string {
    return str.replace(/<\/?b[^>]*>/g, "");
  }

  addSearchTermTag(text: string, str: string): string {
    return text.replace(new RegExp(`(${str})`, "i"), "<b>$&</b>");
  }

  isIncludes(string: string, value: string): boolean {
    return string.toLowerCase().includes(value.toLowerCase());
  }

  revertSelectedItems(items: Array<UDExListItemBase>) {
    items.forEach((item: UDExListItemBase) => {
      if (!item.disabled) {
        item.selected = false;
      }
    });
  }

  syncFilteredItems() {
    const search = this.getSearch();
    if (this._shouldFilterItems) {
      this._filteredItems = this.filterItems(this.getProcessedItems(), search.value);
    } else {
      this._filteredItems = this.getProcessedItems();
    }
  }

  getSlottedItems(items: Array<UDExListItemBase | UDExListGroupHeaderBase>): Array<UDExListItemBase | UDExListGroupHeaderBase> {
    const arr: Array<UDExListItemBase | UDExListGroupHeaderBase> = [];
    items.forEach(item => {
      arr.push(item);
      if (item.isGroup) {
        arr.push(...(item as UDExListGroupHeaderBase).getItems());
      }
    });
    return arr;
  }

  getItemFromList(itemId:string) {
    let menuItem = null;

    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];

      if (item.isGroup) {
        const groupItems = (item as UDExListGroupHeaderBase).getItems();
        menuItem = groupItems.find(({ _id }) => _id === itemId);

        if (menuItem) {
          break;
        }
      } else if (item._id === itemId) {
        menuItem = item;
        break;
      }
    }

    return menuItem;
  }

  fireSelectionChange(itemId: string) {
    this.fireEvent("selection-change", {
      selectedItems: this.selectedItems,
      item: this.getItemFromList(itemId),
    });
  }

  getSearch(): Search {
    return this.shadowRoot!.querySelector<Search>("udex-search")!;
  }

  setItemsModeAndControl(items: Array<UDExFilterMenuItem>) {
    items.forEach(item => {
      item.mode = this.mode;
      item.controlled = this.isControlled;
    });
  }

  getDataTokenId(e:CustomEvent): string {
    return e.detail.item.getAttribute("data-token-id") as string;
  }

  getProcessedItems(): Array<ProcessedItem> {
    return this.items.map(item => {
      if (item.isGroup) {
        const groupItems = (item as UDExListGroupHeaderBase).getItems();
        this.setItemsModeAndControl(groupItems);
      }
      item.mode = this.mode;
      item.controlled = this.isControlled;
      return {
        item,
        groupItems: item.isGroup ? (item as UDExListGroupHeaderBase).getItems() : [],
        isGroup: item.isGroup,
      };
    });
  }

  get getNoResultText(): string {
    const hasTextProperty = this.noResultText !== "undefined" && this.noResultText.length > 0;
    return hasTextProperty ? this.noResultText : UDExSearchableListBase.i18nBundle.getText(NO_MATCHES_FOUND);
  }

  get isListEmpty(): boolean {
    return this._filteredItems.length === 0;
  }

  get selectedItems(): Array<UDExListItemBase> {
    return this.getSlottedItems(this.items).filter(({ selected }) => selected);
  }

  get modeMultiSelect(): boolean {
    return this.mode === ListMode.MultiSelect;
  }

  get modeSingleSelect(): boolean {
    return this.mode === ListMode.SingleSelect;
  }

  get modeRadioGroup(): boolean {
    return this.mode === ListMode.RadioGroup;
  }

  get isControlled(): boolean {
    const isGrouping = this.items.some(({ isGroup }) => isGroup);
    return this.mode === ListMode.MultiSelect && isGrouping;
  }

  get additionalClassName(): string {
    return `udex-searchable-list__header`;
  }

  get groupClassName(): string {
    const classHeader = `udex-searchable-list__header`;
    return this.showSearch ? `${classHeader} udex-searchable-list__header_no-margin` : classHeader;
  }

  get showSearch(): boolean {
    const items = this.getSlottedItems(this.items).filter(({ isGroup }) => !isGroup);
    return items.length > SHOW_SEARCH_AFTER_COUNT && !this.modeRadioGroup;
  }

  static async onDefine(): Promise<void> {
    UDExSearchableListBase.i18nBundle = await getI18nBundle("@udex/web-components");
  }
}

export default UDExSearchableListBase;
