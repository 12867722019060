/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Tooltip from "../../Tooltip.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Tooltip, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-popover", tags, suffix)} class="udex-tooltip__pane" ?hide-arrow="${this.hideArrow}" placement-type="${ifDefined(this.placement)}" horizontal-align="${ifDefined(this.horizontalAlign)}" vertical-align="${ifDefined(this.verticalAlign)}" accessible-name=${ifDefined(this.accessibleName)} accessible-name-ref=${ifDefined(this.accessibleNameRef)} accessible-role=${ifDefined(this.accessibleRole)} prevent-focus-restore>${ this.title ? block1.call(this, context, tags, suffix) : undefined }<slot></slot></${scopeTag("ui5-popover", tags, suffix)}>` : html`<ui5-popover class="udex-tooltip__pane" ?hide-arrow="${this.hideArrow}" placement-type="${ifDefined(this.placement)}" horizontal-align="${ifDefined(this.horizontalAlign)}" vertical-align="${ifDefined(this.verticalAlign)}" accessible-name=${ifDefined(this.accessibleName)} accessible-name-ref=${ifDefined(this.accessibleNameRef)} accessible-role=${ifDefined(this.accessibleRole)} prevent-focus-restore>${ this.title ? block1.call(this, context, tags, suffix) : undefined }<slot></slot></ui5-popover>`;}
function block1 (this: Tooltip, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-tooltip-title">${ifDefined(this.title)}</div>`;}


export default block0;