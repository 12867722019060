import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Popover from "@ui5/webcomponents/dist/Popover.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import getEffectiveScrollbarStyle from "@ui5/webcomponents-base/dist/util/getEffectiveScrollbarStyle.js";
import PopupAccessibleRole from "@ui5/webcomponents/dist/types/PopupAccessibleRole.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import DropdownTemplate from "./generated/templates/DropdownTemplate.lit.js";
import { PlacementType, HorizontalAlign } from "./types/Dropdown.js";
import { TRIGGER } from "./generated/i18n/i18n-defaults.js";

// Styles
import DropdownCss from "./generated/themes/Dropdown.css";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-dropdown</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/Dropdown.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UI5Element
 * @tagname udex-dropdown
 * @public
 */
@customElement({
  tag: "udex-dropdown",
  renderer: litRender,
  styles: [
    DropdownCss,
    getEffectiveScrollbarStyle(),
  ],
  template: DropdownTemplate,
  dependencies: [Popover],
})

/**
 * Fired event when the user clicks the trigger.
 *
 * @event sap.ui.webc.web-components.UDExDropdown#clickTrigger
 * @public
 */
@event("click-trigger")

/**
 * Fired before the dropdown is opened.
 *
 * @event sap.ui.webc.web-components.UDExDropdown#beforeOpen
 * @public
 */
@event("before-open")

/**
 * Fired after the dropdown is opened.
 *
 * @event sap.ui.webc.web-components.UDExDropdown#afterOpen
 * @public
 */
@event("after-open")

/**
 * Fired before the menu is closed.
 *
 * @event sap.ui.webc.web-components.UDExDropdown#beforeClose
 * @public
 */
@event("before-close")

/**
 * Fired after the menu is closed.
 *
 * @event sap.ui.webc.web-components.UDExDropdown#afterClose
 * @public
 */
@event("after-close")
class UDExDropdown extends UI5Element {
  /**
   * Allows control over the height.
   *
   * @public
   */
  @property({ type: String })
    height?: string;

  /**
   * Determines on which side the component is placed at.
   *
   * @default "Bottom"
   * @public
   */
  @property({ type: PlacementType, defaultValue: PlacementType.Bottom })
    placementType!: `${PlacementType}`;

  /**
   * Determines the horizontal alignment of the component.
   *
   * @default "Left"
   * @public
   */
  @property({ type: HorizontalAlign, defaultValue: HorizontalAlign.Left })
    horizontalAlign!: `${HorizontalAlign}`;

  /**
   * Defines the accessible ARIA name of the component.
   *
   * @default ""
   * @public
   */
  @property()
    accessibleName!: string;

  /**
   * Allows setting a custom role.
   * @default "None"
   * @public
   */
  @property({ type: PopupAccessibleRole, defaultValue: PopupAccessibleRole.None })
    accessibleRole!: `${PopupAccessibleRole}`;

  /**
   * Allows setting a custom role for dropdown trigger.
   * @default "combobox"
   * @public
   */
  @property({ type: String, defaultValue: "combobox" })
    triggerAccessibleRole!: string;

  /**
   * Defines the content of the Dropdown.
   *
   * @slot content
   * @public
   */
  @slot({ type: HTMLElement, "default": true })
    content!: Array<HTMLElement>;

  /**
   * Defines the header HTML Element.
   *
   * @slot
   * @public
   */
  @slot({ type: HTMLElement })
    header!: Array<HTMLElement>;

  /**
   * Defines the footer HTML Element.
   *
   * @slot
   * @public
   */
  @slot({ type: HTMLElement })
    footer!: Array<HTMLElement>;

  /**
   * Defines the trigger HTML Element.
   *
   * @slot
   * @public
   */
  @slot({ type: HTMLElement })
    trigger!: Array<HTMLElement>;

  /**
   * Indicates if the dropdown pane is open
   *
   * @public
   * @default false
   */
  @property({ type: Boolean })
    open!: boolean;

  /**
   * Determines if there is no enough space, the component can be placed
   * over the target.
   * @default false
   * @public
   */
  @property({ type: Boolean })
    disableTargetOverlap?: boolean;

  /**
   * Defines direction of the component.
   *
   * @private
   */
  @property({ type: String })
    _direction?: string;

  static i18nBundle: I18nBundle;
  _breakpointSizeXS = 640;

  static async onDefine(): Promise<void> {
    UDExDropdown.i18nBundle = await getI18nBundle("@udex/web-components");
  }

  onAfterRendering() {
    this.handleDirection();
  }

  handleDirection() {
    const filterMenu = this.shadowRoot?.querySelector(".udex-dropdown");
    if (filterMenu) {
      const directionStyles: string = window
        .getComputedStyle(filterMenu)
        .getPropertyValue("direction");
      if (!directionStyles) {
        this._direction = "ltr";
      } else {
        this._direction = directionStyles;
      }
    }
  }

  getDropdownPane() {
    return this.shadowRoot!.querySelector<Popover>("[ui5-popover]")!;
  }

  getTrigger() {
    return this.querySelector<HTMLElement>("[slot=trigger]")!;
  }

  onTriggerClick() {
    const dropdown = this.getDropdownPane();
    const opener = this.getTrigger();
    const isOpenerDisabled = opener.hasAttribute("disabled");

    if (!isOpenerDisabled) {
      dropdown.showAt(opener);
      this.fireEvent("click-trigger");
    }
  }

  /**
   * Open dropdown
   *
   * @public
   */
  onOpen(opener: HTMLElement): void {
    const dropdown = this.getDropdownPane();
    dropdown.showAt(opener);
    this.open = true;
  }

  /**
   * Close dropdown
   *
   * @public
   */
  onClose():void {
    this.open = false;
  }

  afterDropdownClose() {
    this.open = false;
    this.fireEvent("after-close");
  }

  afterDropdownOpen() {
    this.open = true;
    const dropdownPane = this.getDropdownPane();
    const dropdownPaneLeftOffset = +dropdownPane.style.left.split("px")[0];
    if (dropdownPane.offsetWidth + dropdownPaneLeftOffset < this._breakpointSizeXS) {
      this.style.setProperty("--udex-dropdown-left-offset", `${dropdownPane.style.left}`);
    } else {
      dropdownPane.style.setProperty("--udex-dropdown-pane-width", `${this.offsetWidth}px`);
    }
    this.fireEvent("after-open");
  }

  beforeDropdownOpen() {
    const dropdown = this.getDropdownPane();
    const hasContentHeight = !!this?.height?.length;

    if (hasContentHeight) {
      const content = dropdown.shadowRoot!.querySelector("[part=content]") as HTMLElement;
      content.style.maxHeight = <string> this.height;
    }

    this.fireEvent("before-open");
  }

  beforeDropdownClose() {
    this.fireEvent("before-close");
  }

  get hasTriggerSlot(): boolean {
    return !!this.trigger.length;
  }

  get hasHeaderSlot(): boolean {
    return !!this.header.length;
  }

  get hasFooterSlot(): boolean {
    return !!this.footer.length;
  }

  get disableAllowTargetOverlap(): boolean {
    return this.disableTargetOverlap?.toString() !== "true";
  }

  get triggerAccessibleName(): string {
    return UDExDropdown.i18nBundle.getText(TRIGGER);
  }
}

UDExDropdown.define();

export default UDExDropdown;
