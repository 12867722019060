/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Footer from "../../Footer.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<footer class="udex-footer"><div class="udex-footer__content"><div class="udex-footer__contact-social">${ this.logoUrl ? block1.call(this, context, tags, suffix) : undefined }${ this.isContactContainerVisible ? block4.call(this, context, tags, suffix) : undefined }</div>${ this.isContactContainerVisible ? block10.call(this, context, tags, suffix) : undefined }${ this._shouldRenderMobileMenu ? block11.call(this, context, tags, suffix) : block20.call(this, context, tags, suffix) }<${scopeTag("udex-divider", tags, suffix)} class="udex-footer__divider"></${scopeTag("udex-divider", tags, suffix)}>${ this.socialLinksArray.length ? block29.call(this, context, tags, suffix) : undefined }</div><${scopeTag("udex-button", tags, suffix)} class="udex-footer__back-to-top-button" icon="back-to-top" @click="${this.onBackToTopClick}" design="Tertiary" size="Large" accessible-name="back to top"></${scopeTag("udex-button", tags, suffix)}></footer> ` : html`<footer class="udex-footer"><div class="udex-footer__content"><div class="udex-footer__contact-social">${ this.logoUrl ? block1.call(this, context, tags, suffix) : undefined }${ this.isContactContainerVisible ? block4.call(this, context, tags, suffix) : undefined }</div>${ this.isContactContainerVisible ? block10.call(this, context, tags, suffix) : undefined }${ this._shouldRenderMobileMenu ? block11.call(this, context, tags, suffix) : block20.call(this, context, tags, suffix) }<udex-divider class="udex-footer__divider"></udex-divider>${ this.socialLinksArray.length ? block29.call(this, context, tags, suffix) : undefined }</div><udex-button class="udex-footer__back-to-top-button" icon="back-to-top" @click="${this.onBackToTopClick}" design="Tertiary" size="Large" accessible-name="back to top"></udex-button></footer> `;}
function block1 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-footer__logo">${ this.logoLink ? block2.call(this, context, tags, suffix) : block3.call(this, context, tags, suffix) }</div>`;}
function block2 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<a href="${ifDefined(this.parsedRef)}" @click="${this._onLogoClick}" title="${ifDefined(this.logoTitle)}" aria-label="${ifDefined(this.logoAriaLabel)}"><img id="udex-footer__logo-image" src="${ifDefined(this.logoUrl)}" class="udex-footer__logo-image" alt="${ifDefined(this.logoAlt)}"/></a>`;}
function block3 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<img id="udex-footer__logo-image" src="${ifDefined(this.logoUrl)}" class="udex-footer__logo-image" alt="${ifDefined(this.logoAlt)}"/>`;}
function block4 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-footer__contact">${ this.contactNumbersArray.length ? block5.call(this, context, tags, suffix) : undefined }${ this.contactUsLink ? block8.call(this, context, tags, suffix) : undefined }${ this.chatWithUsLink ? block9.call(this, context, tags, suffix) : undefined }</div>`;}
function block5 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="udex-footer__numbers"><div class="udex-footer__icon-container"><${scopeTag("ui5-icon", tags, suffix)} class="udex-footer__icon" name="headset"></${scopeTag("ui5-icon", tags, suffix)}></div><div><div class="udex-footer__numbers-wrapper">${ repeat(this.contactNumbersArray, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block6.call(this, context, tags, suffix, item, index)) }</div>${ this.localCountryNumbersLink ? block7.call(this, context, tags, suffix) : undefined }</div></div>` : html`<div class="udex-footer__numbers"><div class="udex-footer__icon-container"><ui5-icon class="udex-footer__icon" name="headset"></ui5-icon></div><div><div class="udex-footer__numbers-wrapper">${ repeat(this.contactNumbersArray, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block6.call(this, context, tags, suffix, item, index)) }</div>${ this.localCountryNumbersLink ? block7.call(this, context, tags, suffix) : undefined }</div></div>`;}
function block6 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<div><span class="hidden" aria-hidden id="udex-footer__${index}_phone-label">${ifDefined(this.phoneNumberAccessibleName)}${ifDefined(item.country)} : ${ifDefined(item.number)}</span><p class="udex-footer__country">${ifDefined(item.country)}</p><a href="tel:${ifDefined(item.number)}" class="udex-footer__number" @click="${this._onContactPhoneClick}" aria-labelledby="udex-footer__${index}_phone-label">${ifDefined(item.number)}</a></div>`;}
function block7 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<p class="udex-footer__remark">${ifDefined(this.completeListTitle)}<a href="${ifDefined(this.localCountryNumbersLink)}" @click="${this._onContactPhoneLocalNumbersClick}">${ifDefined(this.localCountryTitle)}.</a></p>`;}
function block8 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="udex-footer__contact-us"><div class="udex-footer__icon-container"><${scopeTag("ui5-icon", tags, suffix)} class="udex-footer__icon" name="email"></${scopeTag("ui5-icon", tags, suffix)}></div><span class="hidden" aria-hidden id="udex-footer__contact-us-label">${ifDefined(this.contactUsAccessibleLabel)}</span><a href="${ifDefined(this.contactUsLink)}" class="udex-footer__contact-us-link" aria-labelledby="udex-footer__contact-us-label" @click="${this._onContactEmailClick}">${ifDefined(this.contactUsAccessibleName)}</a></div>` : html`<div class="udex-footer__contact-us"><div class="udex-footer__icon-container"><ui5-icon class="udex-footer__icon" name="email"></ui5-icon></div><span class="hidden" aria-hidden id="udex-footer__contact-us-label">${ifDefined(this.contactUsAccessibleLabel)}</span><a href="${ifDefined(this.contactUsLink)}" class="udex-footer__contact-us-link" aria-labelledby="udex-footer__contact-us-label" @click="${this._onContactEmailClick}">${ifDefined(this.contactUsAccessibleName)}</a></div>`;}
function block9 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="udex-footer__chat-with-us"><div class="udex-footer__icon-container"><${scopeTag("ui5-icon", tags, suffix)} class="udex-footer__icon" name="discussion-2"></${scopeTag("ui5-icon", tags, suffix)}></div><a href="${ifDefined(this.chatWithUsLink)}" class="udex-footer__chat-with-us-link" @click="${this._onContactChatClick}">${ifDefined(this.chatWithUsAccessibleName)}</a></div>` : html`<div class="udex-footer__chat-with-us"><div class="udex-footer__icon-container"><ui5-icon class="udex-footer__icon" name="discussion-2"></ui5-icon></div><a href="${ifDefined(this.chatWithUsLink)}" class="udex-footer__chat-with-us-link" @click="${this._onContactChatClick}">${ifDefined(this.chatWithUsAccessibleName)}</a></div>`;}
function block10 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-divider", tags, suffix)} class="udex-footer__divider"></${scopeTag("udex-divider", tags, suffix)}>` : html`<udex-divider class="udex-footer__divider"></udex-divider>`;}
function block11 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-accordion", tags, suffix)} class="udex-footer__accordion">${ this.quickLinksArray.length ? block12.call(this, context, tags, suffix) : undefined }${ this.trendingLinksArray.length ? block14.call(this, context, tags, suffix) : undefined }${ this.aboutLinksArray.length ? block16.call(this, context, tags, suffix) : undefined }${ this.siteInformationLinksArray.length ? block18.call(this, context, tags, suffix) : undefined }</${scopeTag("udex-accordion", tags, suffix)}>` : html`<udex-accordion class="udex-footer__accordion">${ this.quickLinksArray.length ? block12.call(this, context, tags, suffix) : undefined }${ this.trendingLinksArray.length ? block14.call(this, context, tags, suffix) : undefined }${ this.aboutLinksArray.length ? block16.call(this, context, tags, suffix) : undefined }${ this.siteInformationLinksArray.length ? block18.call(this, context, tags, suffix) : undefined }</udex-accordion>`;}
function block12 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-accordion-item", tags, suffix)} title="${ifDefined(this.quickLinksTitle)}" class="udex-footer__action-item"><ul class="udex-footer__links-list udex-footer__links-list--mobile">${ repeat(this.quickLinksArray, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block13.call(this, context, tags, suffix, item, index)) }</ul></${scopeTag("udex-accordion-item", tags, suffix)}><h3 class="udex-footer__links-item hidden" aria-hidden="true" id="udex-footer__quick-links">${ifDefined(this.quickLinksTitle)}</h3>` : html`<udex-accordion-item title="${ifDefined(this.quickLinksTitle)}" class="udex-footer__action-item"><ul class="udex-footer__links-list udex-footer__links-list--mobile">${ repeat(this.quickLinksArray, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block13.call(this, context, tags, suffix, item, index)) }</ul></udex-accordion-item><h3 class="udex-footer__links-item hidden" aria-hidden="true" id="udex-footer__quick-links">${ifDefined(this.quickLinksTitle)}</h3>`;}
function block13 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<li><a target="${ifDefined(item.target)}" aria-describedby="udex-footer__quick-links" href="${ifDefined(item.url)}" data-id="${ifDefined(item.id)}" @click="${this._onQuickLinksClick}">${ifDefined(item.title)}</a></li>`;}
function block14 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-accordion-item", tags, suffix)} title="${ifDefined(this.trendingTitle)}" class="udex-footer__action-item"><ul class="udex-footer__links-list udex-footer__links-list--mobile">${ repeat(this.trendingLinksArray, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block15.call(this, context, tags, suffix, item, index)) }</ul></${scopeTag("udex-accordion-item", tags, suffix)}><h3 class="udex-footer__links-item hidden" aria-hidden="true" id="udex-footer__trending">${ifDefined(this.trendingTitle)}</h3>` : html`<udex-accordion-item title="${ifDefined(this.trendingTitle)}" class="udex-footer__action-item"><ul class="udex-footer__links-list udex-footer__links-list--mobile">${ repeat(this.trendingLinksArray, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block15.call(this, context, tags, suffix, item, index)) }</ul></udex-accordion-item><h3 class="udex-footer__links-item hidden" aria-hidden="true" id="udex-footer__trending">${ifDefined(this.trendingTitle)}</h3>`;}
function block15 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<li><a target="${ifDefined(item.target)}" aria-describedby="udex-footer__footer__trending" href="${ifDefined(item.url)}" @click="${this._onTrendingClick}" data-id="${ifDefined(item.id)}">${ifDefined(item.title)}</a></li>`;}
function block16 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-accordion-item", tags, suffix)} title="${ifDefined(this.aboutSapTitle)}" class="udex-footer__action-item"><ul class="udex-footer__links-list udex-footer__links-list--mobile">${ repeat(this.aboutLinksArray, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block17.call(this, context, tags, suffix, item, index)) }</ul></${scopeTag("udex-accordion-item", tags, suffix)}><h3 class="udex-footer__links-item hidden" aria-hidden="true" id="udex-footer__about">${ifDefined(this.aboutSapTitle)}</h3>` : html`<udex-accordion-item title="${ifDefined(this.aboutSapTitle)}" class="udex-footer__action-item"><ul class="udex-footer__links-list udex-footer__links-list--mobile">${ repeat(this.aboutLinksArray, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block17.call(this, context, tags, suffix, item, index)) }</ul></udex-accordion-item><h3 class="udex-footer__links-item hidden" aria-hidden="true" id="udex-footer__about">${ifDefined(this.aboutSapTitle)}</h3>`;}
function block17 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<li><a target="${ifDefined(item.target)}" aria-describedby="udex-footer__about" href="${ifDefined(item.url)}" data-id="${ifDefined(item.id)}" @click="${this._onAboutSapClick}">${ifDefined(item.title)}</a></li>`;}
function block18 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-accordion-item", tags, suffix)} title="${ifDefined(this.siteInformationTitle)}" class="udex-footer__action-item"><ul class="udex-footer__links-list udex-footer__links-list--mobile">${ repeat(this.siteInformationLinksArray, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block19.call(this, context, tags, suffix, item, index)) }</ul></${scopeTag("udex-accordion-item", tags, suffix)}><h3 class="udex-footer__links-item hidden" aria-hidden="true" id="udex-footer__site-information">${ifDefined(this.siteInformationTitle)}</h3>` : html`<udex-accordion-item title="${ifDefined(this.siteInformationTitle)}" class="udex-footer__action-item"><ul class="udex-footer__links-list udex-footer__links-list--mobile">${ repeat(this.siteInformationLinksArray, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block19.call(this, context, tags, suffix, item, index)) }</ul></udex-accordion-item><h3 class="udex-footer__links-item hidden" aria-hidden="true" id="udex-footer__site-information">${ifDefined(this.siteInformationTitle)}</h3>`;}
function block19 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<li><a target="${ifDefined(item.target)}" aria-describedby="udex-footer__site-information" href="${ifDefined(item.url)}" data-id="${ifDefined(item.id)}" @click="${this._onSiteInformationClick}">${ifDefined(item.title)}</a></li>`;}
function block20 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-footer__links">${ this.quickLinksArray.length ? block21.call(this, context, tags, suffix) : undefined }${ this.trendingLinksArray.length ? block23.call(this, context, tags, suffix) : undefined }${ this.aboutLinksArray.length ? block25.call(this, context, tags, suffix) : undefined }${ this.siteInformationLinksArray.length ? block27.call(this, context, tags, suffix) : undefined }</div>`;}
function block21 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-footer__links-group udex-footer__quick-links"><h3 class="udex-footer__links-item" id="udex-footer__quick-links">${ifDefined(this.quickLinksTitle)}</h3><ul class="udex-footer__links-list udex-footer__links-list--desktop">${ repeat(this.quickLinksArray, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block22.call(this, context, tags, suffix, item, index)) }</ul></div>`;}
function block22 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<li><a target="${ifDefined(item.target)}" aria-describedby="udex-footer__quick-links" data-id="${ifDefined(item.id)}" href="${ifDefined(item.url)}" @click="${this._onQuickLinksClick}">${ifDefined(item.title)}</a></li>`;}
function block23 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-footer__links-group udex-footer__trending-links"><h3 class="udex-footer__links-item" id="udex-footer__trending">${ifDefined(this.trendingTitle)}</h3><ul class="udex-footer__links-list udex-footer__links-list--desktop">${ repeat(this.trendingLinksArray, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block24.call(this, context, tags, suffix, item, index)) }</ul></div>`;}
function block24 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<li><a target="${ifDefined(item.target)}" aria-describedby="udex-footer__footer__trending" data-id="${ifDefined(item.id)}" href="${ifDefined(item.url)}" @click="${this._onTrendingClick}">${ifDefined(item.title)}</a></li>`;}
function block25 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-footer__links-group udex-footer__about-links"><h3 class="udex-footer__links-item" id="udex-footer__about">${ifDefined(this.aboutSapTitle)}</h3><ul class="udex-footer__links-list udex-footer__links-list--desktop">${ repeat(this.aboutLinksArray, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block26.call(this, context, tags, suffix, item, index)) }</ul></div>`;}
function block26 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<li><a target="${ifDefined(item.target)}" aria-describedby="udex-footer__about" data-id="${ifDefined(item.id)}" href="${ifDefined(item.url)}" @click="${this._onAboutSapClick}">${ifDefined(item.title)}</a></li>`;}
function block27 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-footer__links-group udex-footer__site-information-links"><h3 class="udex-footer__links-item" id="udex-footer__site-information">${ifDefined(this.siteInformationTitle)}</h3><ul class="udex-footer__links-list udex-footer__links-list--desktop">${ repeat(this.siteInformationLinksArray, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block28.call(this, context, tags, suffix, item, index)) }</ul></div>`;}
function block28 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<li><a target="${ifDefined(item.target)}" aria-describedby="udex-footer__site-information" data-id="${ifDefined(item.id)}" href="${ifDefined(item.url)}" @click="${this._onSiteInformationClick}">${ifDefined(item.title)}</a></li>`;}
function block29 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-footer__social-container">${ repeat(this.socialLinksArray, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block30.call(this, context, tags, suffix, item, index)) }</div>`;}
function block30 (this: Footer, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<a target="${ifDefined(item.target)}" href="${ifDefined(item.url)}" class="udex-footer__social-icon" data-name="${ifDefined(item.name)}" data-id="${ifDefined(item.id)}" @click="${this._onSocialMediaLinkClick}"><img data-name="${ifDefined(item.name)}" data-id="${ifDefined(item.id)}" alt="${ifDefined(item.name)}${ifDefined(this.socialIconAccessibleName)}" src="${ifDefined(item.iconUrl)}" loading="lazy" /></a>`;}


export default block0;