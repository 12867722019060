/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type MessageStrip from "../../MessageStrip.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: MessageStrip, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="${classMap(this.classes.root as ClassMapValue)}" id="${ifDefined(this._id)}" role="note" aria-labelledby="${ifDefined(this._id)}">${ !this.hideIcon ? block1.call(this, context, tags, suffix) : undefined }<span class="ui5-hidden-text">${ifDefined(this.hiddenText)}</span><span class="ui5-message-strip-text"><slot></slot></span>${ !this.hideCloseButton ? block4.call(this, context, tags, suffix) : undefined }</div>`;}
function block1 (this: MessageStrip, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="ui5-message-strip-icon-wrapper" aria-hidden="true">${ this.iconProvided ? block2.call(this, context, tags, suffix) : block3.call(this, context, tags, suffix) }</div>`;}
function block2 (this: MessageStrip, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<slot name="icon"></slot>`;}
function block3 (this: MessageStrip, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} name="${ifDefined(this.standardIconName)}" class="ui5-message-strip-icon"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon name="${ifDefined(this.standardIconName)}" class="ui5-message-strip-icon"></ui5-icon>`;}
function block4 (this: MessageStrip, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-button", tags, suffix)} icon="decline" design="Transparent" class="ui5-message-strip-close-button" tooltip="${ifDefined(this._closeButtonText)}" @click=${this._closeClick}></${scopeTag("ui5-button", tags, suffix)}>` : html`<ui5-button icon="decline" design="Transparent" class="ui5-message-strip-close-button" tooltip="${ifDefined(this._closeButtonText)}" @click=${this._closeClick}></ui5-button>`;}


export default block0;