/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Tokenizer from "../../Tokenizer.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Tokenizer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="${classMap(this.classes.wrapper as ClassMapValue)}"><span id="${ifDefined(this._id)}-hiddenText" class="ui5-hidden-text" aria-hidden="true">${ifDefined(this.tokenizerLabel)}</span><div class="${classMap(this.classes.content as ClassMapValue)}" @ui5-delete="${ifDefined(this._delete)}" @click="${this._click}" @mousedown="${this._onmousedown}" @keydown="${this._onkeydown}" @ui5-select="${ifDefined(this.onTokenSelect)}" role="listbox" aria-labelledby="${ifDefined(this._id)}-hiddenText">${ repeat(this.tokens, (item, index) => (item as typeof item & {_id?: any})._id || index, (item, index: number) => block1.call(this, context, tags, suffix, item, index)) }</div>${ this.showNMore ? block2.call(this, context, tags, suffix) : undefined }</div>`;}
function block1 (this: Tokenizer, context: UI5Element, tags: string[], suffix: string | undefined, item: any, index: number) { return html`<slot name="${ifDefined(item._individualSlot)}"></slot>`;}
function block2 (this: Tokenizer, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span @click="${this._openMorePopoverAndFireEvent}" class="ui5-tokenizer-more-text" part="n-more-text">${ifDefined(this._nMoreText)}</span>`;}


export default block0;