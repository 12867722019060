/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Link from "../../Link.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Link, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<a class="ui5-link-root" role="${ifDefined(this.effectiveAccRole)}" href="${ifDefined(this.parsedRef)}" target="${ifDefined(this.target)}" rel="${ifDefined(this._rel)}" tabindex="${ifDefined(this.effectiveTabIndex)}" title="${ifDefined(this.title)}" ?disabled="${this.disabled}" aria-label="${ifDefined(this.ariaLabelText)}" aria-haspopup="${ifDefined(this._hasPopup)}" aria-expanded="${ifDefined(this.accessibilityAttributes.expanded)}" @focusin=${this._onfocusin} @focusout=${this._onfocusout} @click=${this._onclick} @keydown=${this._onkeydown} @keyup=${this._onkeyup}><slot></slot>${ this.hasLinkType ? block1.call(this, context, tags, suffix) : undefined }</a>`;}
function block1 (this: Link, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="ui5-hidden-text">${ifDefined(this.linkTypeText)}</span>`;}


export default block0;