import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";

import RadioButton from "@ui5/webcomponents/dist/RadioButton.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";

// Styles
import radioButtonCss from "@ui5/webcomponents/dist/generated/themes/RadioButton.css.js";
import RadioButtonCss from "./generated/themes/RadioButton.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-radio-button</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/RadioButton.js";</code>
 *
 * @constructor
 * @extends RadioButton
 * @public
 */
@customElement({
  tag: "udex-radio-button",
  renderer: litRender,
  styles: [radioButtonCss, RadioButtonCss],
  dependencies: [RadioButton],
})
class UDExRadioButton extends RadioButton {
  /**
   * Define if arrow icons for last and first page are visible.
   *
   * @public
   * @default false
   */
  @property({ type: Boolean })
    displayOnly!: boolean;

  onAfterRendering() {
    this.readonly = this.readonly || this.displayOnly;
  }
}

UDExRadioButton.define();

export default UDExRadioButton;
