import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import { ListItemSize, ListMode, ListStyleType } from "./types/List.js";

/**
 * @class
 *
 * A class to serve as a foundation
 * for the <code>UDExListItem</code>,<code>UDExFilterMenuItem</code> and <code>UDExGroupHeaderBase</code> classes.
 *
 * @constructor
 * @author SAP SE
 * @extends UI5Element
 * @public
 */

@customElement({
  renderer: litRender,
})

class UDExListItemBase extends UI5Element {
  /**
   * Defines the <code>label</code> for the item.
   *
   * @public
   */
  @property()
    label!: string;

  /**
   * Defines whether a visual separator should be rendered after the item.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    divider!: boolean;

  /**
   * Defines whether <code> list item</code> is in disabled state.
   *
   * @public
   */
  @property({ type: Boolean })
    disabled!: boolean;

  /**
   * Defines the supportingText, displayed in the end of the item.
   *
   * @public
   */
  @property()
    supportingText!: string;

  /**
   * Defines the selected state of the item.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    selected!: boolean;

  /**
   * Defines whether the selection of a  list is displayed as partially selected.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    indeterminate?: boolean;

  /**
   * Defines the controlled state of the item.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    controlled?: boolean;

  /**
   * Defines the mode of the item.
   *
   * @default "SingleSelect"
   * @public
   */
  @property({ type: ListMode, defaultValue: ListMode.SingleSelect })
    mode!: `${ListMode}`;

  /**
   * Defines the size of the item.
   *
   * @defaultvalue "Standard"
   * @public
   */
  @property({ type: ListItemSize, defaultValue: ListItemSize.Standard })
    size?: `${ListItemSize}`;

  /**
   * Defines the type of the item.
   *
   * @defaultvalue "None"
   * @public
   */
  @property({ type: ListStyleType, defaultValue: ListStyleType.None })
    type!: `${ListStyleType}`;

  @property({ type: String, defaultValue: "" })
    _icon?: string;

  @property({ type: String })
    _tabIndex!: string;

  get _effectiveTabIndex() {
    if (this.disabled) {
      return -1;
    }
    return this._tabIndex;
  }

  get numberOfItem() {
    return [...this.parentNode!.children].indexOf(this) + 1;
  }

  get isGroup(): boolean {
    return false;
  }

  get modeRadioGroup() {
    return this.mode === ListMode.RadioGroup;
  }

  get modeMultiSelect() {
    return this.mode === ListMode.MultiSelect;
  }

  get modeSingleSelect() {
    return this.mode === ListMode.SingleSelect;
  }

  get modeMultiSelectOrRadioGroup() {
    return this.modeMultiSelect || this.modeRadioGroup;
  }

  get typeNumber() {
    return this.type === ListStyleType.Number;
  }

  get typeCircle() {
    return this.type === ListStyleType.Circle;
  }

  get typeIcon() {
    return this.type === ListStyleType.Icon;
  }
}

export default UDExListItemBase;
