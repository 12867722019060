import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import { isEnter, isSpace } from "@ui5/webcomponents-base/dist/Keys.js";
import Icon from "@ui5/webcomponents/dist/Icon.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";
import I18nBundle, { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import UDExCheckbox from "./Checkbox.js";
import UDExListItemBase from "./ListItemBase.js";
import UDExListGroupHeaderBase from "./ListGroupHeaderBase.js";
import UDExAvatar, { AvatarShape, AvatarSize } from "./Avatar.js";
import UDExButton from "./Button.js";
import {
  ListItemSize, ListMode, AdditionalTextState,
} from "./types/List.js";
import UDExRadioButton from "./RadioButton.js";
import {
  DELETE_BUTTON, EDIT_BUTTON, INDETERMINATE, SELECTED, UNSELECTED,
} from "./generated/i18n/i18n-defaults.js";

import ListItemTemplate from "./generated/templates/ListItemTemplate.lit.js";

// Styles
import ListItemCss from "./generated/themes/ListItem.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-list-item</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/ListItem.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UDExListItemBase
 * @tagname udex-list-item
 * @public
 */

@customElement({
  tag: "udex-list-item",
  renderer: litRender,
  styles: ListItemCss,
  template: ListItemTemplate,
  dependencies: [
    UDExAvatar,
    UDExButton,
    UDExRadioButton,
    UDExCheckbox,
    Icon,
  ],
})

/**
 * Fired event when the user click on a list item.
 *
 * @event sap.ui.webc.web-components.UDExListItem#itemPress
 * @public
 */
@event("item-press", {
  detail: {
    selected: { type: Boolean },
    item: { type: HTMLElement },
  },
})
/**
 * Fired event when the user focuses on a list item.
 *
 * @event sap.ui.webc.web-components.UDExListItem#itemFocus
 * @public
 */
@event("item-focus", {
  detail: {
    item: { type: HTMLElement },
  },
})
/**
 * Fired event when the user click on the delete icon.
 *
 * @event sap.ui.webc.web-components.UDExListItem#deletePress
 * @public
 */
@event("delete-press", {
  detail: {
    item: { type: HTMLElement },
  },
})
/**
 * Fired event when the user click on the edit icon.
 *
 * @event sap.ui.webc.web-components.UDExListItem#editPress
 * @public
 */
@event("edit-press", {
  detail: {
    item: { type: HTMLElement },
  },
})

class UDExListItem extends UDExListItemBase {
  /**
   * Defines the accessible name of the item.
   *
   * @default ""
   * @public
   */
  @property()
    accessibleName?: string;

  /**
   * Defines the position for flag image.
   *
   * @public
   */
  @property({ type: String })
    flagPosition?: string;

  /**
   * Defines the image, displayed at the start of the item.
   *
   * @public
   */
  @property({ type: Boolean })
    flag?: boolean;

  /**
   * Indicates if the item is active.
   *
   * @private
   */
  @property({ type: Boolean })
    active!: boolean;

  /**
   * Indicates if the item is on focus
   *
   * @private
   */
  @property({ type: Boolean })
    focused!: boolean;

  /**
   * Defines the additionalText, displayed at the end of the list item.
   *
   * @public
   */
  @property({ type: String })
    additionalText?: string;

  /**
   * Defines the state of the additionalText.
   *
   * @defaultvalue "Circle"
   * @public
   */
  @property({ type: AdditionalTextState, defaultValue: AdditionalTextState.None })
    additionalTextState?: `${AdditionalTextState}`;

  /**
   * Allows control over the image source.
   *
   * @public
   */
  @property({ type: String })
    image?: string;

  /**
   * Allows control over the alternative text of the image.
   *
   * @public
   */
  @property({ type: String })
    imageAlt?: string;

  /**
   * Defines the shape of the image.
   *
   * @defaultvalue "Circle"
   * @public
   */
  @property({ type: AvatarShape, defaultValue: AvatarShape.Circle })
    imageShape?: `${AvatarShape}`;

  /**
   * Allows control over the size of the image.
   *
   * @defaultvalue "S"
   * @public
   */
  @property({ type: AvatarSize, defaultValue: AvatarSize.S })
    imageSize!: `${AvatarSize}`;

  /**
   * Defines the name of the edit icon, displayed in the end of the list item.
   *
   * @defaultvalue ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    editIcon?: string;

  /**
   * Defines the name of the delete icon, displayed in the end of the list item.
   *
   * @defaultvalue ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    deleteIcon?: string;

  /**
   * Slot for list item content.
   *
   * @slot
   * @public
   */
  @slot({ type: Node, "default": true })
    content!: Array<Node>;

  /**
  * Defines the role attribute of component
  *
  * @public
  */
  @property({ type: String })
    accessibleRole!: string;

  @property({ type: String })
    _additionalClassName!: string;

  deactivate: () => void;

  static i18nBundle: I18nBundle;

  constructor() {
    super();

    this.deactivate = () => {
      if (this.active) {
        this.active = false;
      }
    };
  }

  static async onDefine(): Promise<void> {
    UDExListItem.i18nBundle = await getI18nBundle("@udex/web-components");
  }

  onEnterDOM() {
    document.addEventListener("mouseup", this.deactivate);
    if (isDesktop()) {
      this.setAttribute("desktop", "");
    }
  }

  onAfterRendering() {
    if (!this.accessibleRole || this.accessibleRole === "listitem") {
      this.shadowRoot?.querySelector("li")?.removeAttribute("aria-selected");
    }
  }

  onExitDOM() {
    document.removeEventListener("mouseup", this.deactivate);
  }

  handleMouseDown() {
    this.activate();
  }

  handleMouseUp() {
    this.deactivate();
  }

  handleKeyUp(e: KeyboardEvent) {
    e.preventDefault();
    if (isSpace(e) || isEnter(e)) {
      this.deactivate();
    }
  }

  handleKeyDown(e: KeyboardEvent) {
    if (isSpace(e)) {
      e.preventDefault();
    }
    if (isSpace(e) || isEnter(e)) {
      this.activate();
      this.handleItemClick(e);
    }
  }

  handleKeyDownIcon(e: KeyboardEvent) {
    e.stopPropagation();
  }

  handleItemClick(e: Event) {
    if (this.disabled) {
      return;
    }
    if (!this.indeterminate && !this.controlled) {
      this.selected = !this.selected;
    }
    this.fireItemPress(e);
  }

  handleFocusIn() {
    if (!this.disabled) {
      this.focused = true;
      this.fireEvent("item-focus", {
        item: this,
      });
    }
  }

  handleFocusOut() {
    if (!this.disabled) {
      this.focused = false;
      this.deactivate();
    }
  }

  handleDeleteClick(e: Event) {
    if (!this.disabled) {
      e.stopPropagation();
      this.fireEvent("delete-press", {
        item: this,
      });
    }
  }

  handleEditClick(e: Event) {
    if (!this.disabled) {
      e.stopPropagation();
      this.fireEvent("edit-press", {
        item: this,
      });
    }
  }

  fireItemPress(e: Event) {
    if (isEnter(e as KeyboardEvent)) {
      e.preventDefault();
    }
    this.fireEvent("item-press", {
      selected: this.selected,
      item: this,
    });
  }

  activate() {
    if (!this.disabled) {
      this.active = true;
    }
  }

  get className() {
    const isGroupChild = this?.parentElement as UDExListGroupHeaderBase;
    const className = "udex-list__item";
    const controlledClassName = this.controlled ? `${className}_controlled` : "";
    const groupClassName = isGroupChild?.isGroup ? `${className}_group` : "";
    const largeSizeClassName = this.isLargeSize ? `${className}_large` : "";
    return `${className} ${groupClassName} ${this._additionalClassName} ${controlledClassName} ${largeSizeClassName}`;
  }

  get roleItem() {
    return this.accessibleRole.length > 0 ? this.accessibleRole : "listitem";
  }

  get _ariaDisabled() {
    return this.disabled ? true : undefined;
  }

  get modeSingleContent() {
    return this.mode === ListMode.SingleContent;
  }

  get modeRadioGroup() {
    return this.mode === ListMode.RadioGroup;
  }

  get modeSelected() {
    return [ListMode.SingleSelect, ListMode.MultiSelect, ListMode.RadioGroup].includes(this.mode as ListMode);
  }

  get isLargeSize() {
    return this.size === ListItemSize.Large;
  }

  get stateAccessibleName(): string {
    if (this.indeterminate) { return UDExListItem.i18nBundle.getText(INDETERMINATE); }
    return `${this.selected ? UDExListItem.i18nBundle.getText(SELECTED) : UDExListItem.i18nBundle.getText(UNSELECTED)} `;
  }

  get editButtonAccessibleName(): string {
    return UDExListItem.i18nBundle.getText(EDIT_BUTTON);
  }

  get deleteButtonAccessibleName(): string {
    return UDExListItem.i18nBundle.getText(DELETE_BUTTON);
  }
}

UDExListItem.define();

export default UDExListItem;
