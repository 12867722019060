/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type TextField from "../../TextField.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: TextField, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-text-field__container">${ this.label ? block1.call(this, context, tags, suffix) : undefined }<div class="udex-text-field__wrap"><input id="${ifDefined(this._id)}-input" class="udex-text-field__input" type="${ifDefined(this.type)}" .value="${ifDefined(this.value)}" @change="${this.handleChange}" @input="${this.handleInput}" @focus="${this.handleFocus}" @blur="${this.handleBlur}" @click="${this.handleClick}" @keydown="${this.handleKeyDown}" maxlength="${ifDefined(this.maxlength)}" ?disabled="${this.disabled}" ?readonly="${this.isInputReadonly}" ?required="${this.required}" aria-required="${ifDefined(this.required)}" aria-readonly="${ifDefined(this.isInputReadonly)}" ?aria-disabled="${this.readonlyNotDisabled}" aria-invalid="${ifDefined(this.accInfo.ariaInvalid)}" aria-describedby="${ifDefined(this.accInfo.ariaDescribedBy)}" aria-haspopup="${ifDefined(this.accInfo.ariaHasPopup)}" aria-label="${ifDefined(this.accInfo.ariaLabel)}" aria-labelledby="${ifDefined(this.accInfo.ariaLabelledBy)}" part="textfield-input" min="${ifDefined(this.min)}" max="${ifDefined(this.max)}" step="${ifDefined(this.step)}" />${ this.icon ? block3.call(this, context, tags, suffix) : undefined }</div>${ this.supportingText ? block4.call(this, context, tags, suffix) : undefined }${ this.supportingText ? block5.call(this, context, tags, suffix) : undefined }${ this.hasValueState ? block6.call(this, context, tags, suffix) : undefined }${ this.readonly ? block7.call(this, context, tags, suffix) : undefined }</div>`;}
function block1 (this: TextField, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<label for="${ifDefined(this._id)}-input" class="${ifDefined(this.labelClasses)}" part="label">${ifDefined(this.label)}${ this.required ? block2.call(this, context, tags, suffix) : undefined }</label>`;}
function block2 (this: TextField, context: UI5Element, tags: string[], suffix: string | undefined) { return html` * `;}
function block3 (this: TextField, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-control-button", tags, suffix)} accessible-name="${ifDefined(this.accessibleIconName)}" tooltip=${ifDefined(this.showButtonTooltip)} ?active="${this.active}" ?disabled="${this.disabled}" ?readonly="${this.readonly}" size="Large" design="${ifDefined(this.valueState)}" class="udex-text-field_icon-container" icon="${ifDefined(this.icon)}" @click="${this.handleIconClick}"></${scopeTag("udex-control-button", tags, suffix)}>` : html`<udex-control-button accessible-name="${ifDefined(this.accessibleIconName)}" tooltip=${ifDefined(this.showButtonTooltip)} ?active="${this.active}" ?disabled="${this.disabled}" ?readonly="${this.readonly}" size="Large" design="${ifDefined(this.valueState)}" class="udex-text-field_icon-container" icon="${ifDefined(this.icon)}" @click="${this.handleIconClick}"></udex-control-button>`;}
function block4 (this: TextField, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("udex-status-message", tags, suffix)} ?disabled="${this.disabled}" supporting-text="${ifDefined(this.supportingText)}" value-state="${ifDefined(this.valueState)}"></${scopeTag("udex-status-message", tags, suffix)}>` : html`<udex-status-message ?disabled="${this.disabled}" supporting-text="${ifDefined(this.supportingText)}" value-state="${ifDefined(this.valueState)}"></udex-status-message>`;}
function block5 (this: TextField, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span id="${ifDefined(this._id)}-supportingTextDesc" class="hidden-text">${ifDefined(this.supportingText)}</span>`;}
function block6 (this: TextField, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span id="${ifDefined(this._id)}-valueStateDesc" class="hidden-text">${ifDefined(this.valueStateText)}</span>`;}
function block7 (this: TextField, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span id="${ifDefined(this._id)}-stateReadOnlyDesc" class="hidden-text">${ifDefined(this.readOnlyStateText)}</span>`;}


export default block0;