import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import executeTemplate from "@ui5/webcomponents-base/dist/renderer/executeTemplate.js";
import {
  isEnter,
  isSpace,
  isDelete,
  isTabPrevious,
  isTabNext,
  isLeft,
  isRight,
  isDown,
  isUp,
  isEscape,
  isBackSpace,
  isShift,
} from "@ui5/webcomponents-base/dist/Keys.js";
import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import Tokenizer from "@ui5/webcomponents/dist/Tokenizer.js";
import Icon from "@ui5/webcomponents/dist/Icon.js";
import Token from "@ui5/webcomponents/dist/Token.js";
import ResizeHandler, { ResizeObserverCallback } from "@ui5/webcomponents-base/dist/delegate/ResizeHandler.js";
import ResponsivePopover from "@ui5/webcomponents/dist/ResponsivePopover.js";
import UDExList from "./List.js";
import UDExListItem from "./ListItem.js";
import UDExListGroupHeader from "./ListGroupHeader.js";
import UDExListSelectAll from "./ListSelectAll.js";
import UDExSelectBoxItem from "./SelectBoxItem.js";
import UDExSelectBoxGroupHeader from "./SelectBoxGroupHeader.js";
import UDExSearchableListBase from "./SearchableListBase.js";
import UDExSearch from "./Search.js";
import UDExTag from "./Tag.js";
import UDExTooltip, { PlacementType } from "./Tooltip.js";
import UDExButton from "./Button.js";
import ValueState from "./types/common.js";

import { SELECT_OPTIONS } from "./generated/i18n/i18n-defaults.js";
import SelectBoxTemplate from "./generated/templates/SelectBoxTemplate.lit.js";
import SearchableListBaseTemplate from "./generated/templates/SearchableListBaseTemplate.lit";

// Styles
import SelectBoxCss from "./generated/themes/SelectBox.css.js";
import TextFieldCss from "./generated/themes/TextField.css.js";
import SearchableListCss from "./generated/themes/SearchableList.css.js";
import UDExStatusMessage from "./StatusMessage.js";
import UDExControlButton from "./ControlButton.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-select-box</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/UDExSelectBox.js";</code>
 *
 * @constructor
 * @extends UDExSearchableListBase
 * @tagname udex-select-box
 * @public
 */
@customElement({
  tag: "udex-select-box",
  renderer: litRender,
  styles: [TextFieldCss, SelectBoxCss, SearchableListCss],
  template: SelectBoxTemplate,
  dependencies: [
    UDExSelectBoxGroupHeader,
    UDExListItem,
    UDExListGroupHeader,
    UDExListSelectAll,
    UDExSelectBoxItem,
    UDExList,
    UDExTooltip,
    UDExSearch,
    UDExButton,
    Tokenizer,
    UDExTag,
    Token,
    Icon,
    UDExStatusMessage,
    UDExControlButton,
    ResponsivePopover,
  ],
})

/**
 * Fired event when the user clicks on the reset button in the search.
 *
 * @event sap.ui.webc.web-components.UDExSelectBox#resetSearch
 * @public
 */
@event("remove", {
  detail: {
    item: { type: HTMLElement },
  },
})

/**
 * Fired event when the user clicks on the More button.
 *
 * @event sap.ui.webc.web-components.UDExSelectBox#showMore
 * @public
 */
@event("show-more")

class UDExSelectBox extends UDExSearchableListBase {
  /**
   * Determines if there is no enough space, the component can be placed
   * over the target.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    disableTargetOverlap?: boolean;

  /**
   * Defines whether the component is required.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    required!: boolean;

  /**
   * Defines whether the component is in disabled state.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    disabled!: boolean;

  /**
   * Allows control over the height.
   * @public
   */
  @property({ type: String })
    height?: string;

  /**
   * Defines label text for field container.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    label!: string;

  /**
   * Defines the accessible ARIA name of the component.
   *
   * @default ""
   * @public
   */
  @property()
    accessibleName!: string;

  /**
   * Receives id (or many ids) of the elements that label the component.
   * @default ""
   * @public
   */
  @property({ defaultValue: "" })
    accessibleNameRef!: string;

  /**
   * Defines whether the component is read-only.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    readonly!: boolean;

  /**
   * Defines whether the component is display-only.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    displayonly!: boolean;

  /**
   * Defines the value state of the trigger container.
   *
   * @default "Standard"
   * @public
   */
  @property({ type: ValueState, defaultValue: ValueState.Standard })
    valueState!: `${ValueState}`;

  /**
   * Defines supporting text under the trigger container.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    supportingText!: string;

  /**
   * Determines on which side of the component the Tooltip is displayed.
   *
   * @default "Bottom"
   * @public
   */
  @property({ type: PlacementType, defaultValue: PlacementType.Bottom })
    tooltipPlacement!: `${PlacementType}`;

  /**
   * @private
   */
  @property({ type: String, noAttribute: true })
    _direction?: string;

  /**
   * @default false
   * @private
   */
  @property({ type: Boolean })
    _focused!: boolean;

  /**
   * @default false
   * @private
   */
  @property({ type: Boolean })
    _open!: boolean;

  /**
   * @default false
   * @private
   */
  @property({ type: Boolean })
    _showTagsDropdownContent!: boolean;

  _trigger!: HTMLElement;
  _popover!: ResponsivePopover;
  _tokenizer!: Tokenizer;
  _search!: UDExSearch;
  _singleSelectedOption!: string;
  _truncatableTag!: UDExTag;
  _tags!: Array<UDExTag>;
  _inactiveTabIndex: number = -1;
  _activeTabIndex: number = 0;
  _openedByTrigger: boolean = true;

  _handleResizeBound: ResizeObserverCallback;

  constructor() {
    super();
    this._shouldFilterItems = false;
    this._handleResizeBound = this._handleResize.bind(this);
    this._filteredItems = [];
  }

  onEnterDOM() {
    ResizeHandler.register(this, this._handleResizeBound);
  }

  onExitDOM() {
    ResizeHandler.deregister(this, this._handleResizeBound);
  }

  onAfterRendering() {
    this._tokenizer = this.getTokenizer();
    this._popover = this.getPopover();
    this._search = this.getSearch();
    this._trigger = this.getTrigger();
    this._tags = this.getTags();
    this.handleDirection();
    if (this._tokenizer?.tokens.length > 0) {
      this.handleOutsideClick();
      this.resetTagsTabIndexes();
    }
  }

  onBeforeRendering() {
    super.onBeforeRendering();

    if (this.modeSingleSelect && this.hasSelectedItems) {
      this.handleSingleSelectMode();
    }
  }

  _handleResize() {
    this._popover.style.setProperty("--udex-dropdown-pane-width", `${this.offsetWidth}px`);
  }

  handleItemPress(e:CustomEvent) {
    this.onItemPress(e);

    if (this.modeSingleSelect) {
      this.handleSingleSelectMode();
      this.closeDropdown();
    }
  }

  handleSingleSelectMode() {
    this._singleSelectedOption = this.selectedItems[0]?.label;
  }

  handleSelectAll(e:CustomEvent) {
    this.onHeaderPress(e);
    this.fireEvent("select-all");
  }

  handleHeaderPress(e:CustomEvent) {
    this.onHeaderPress(e);
  }

  handleValueSearch(e:CustomEvent) {
    this.onSearch(e);
  }

  handleSubmitSearch() {
    this.closeDropdown();
    this.onSubmitSearch();
  }

  handleResetSearch() {
    this.onResetSearch();
  }

  handleFocusIn() {
    if (this.disabled) {
      return;
    }
    this._focused = true;
  }

  handleFocusOut() {
    this._focused = false;
  }

  handleDropdownBeforeOpen() {
    if (this.height) {
      const content = this._popover.shadowRoot!.querySelector("[part=content]") as HTMLElement;
      content.style.maxHeight = this.height;
    }
    this.fireEvent("before-open");
  }

  handleDropdownAfterClose() {
    if (this._tokenizer?._tokensCount > 0) {
      this._tokenizer.expanded = false;
    }
    this.handleUnselectTruncatableTag();
    this._open = false;
  }

  showMoreItems() {
    this._tokenizer.expanded = true;
    this._showTagsDropdownContent = false;
    this.openDropdown();
    this.handleUnselectTruncatableTag();
    this.fireEvent("show-more");
  }

  showMoreReadOnlyItems() {
    const tooltip = this.shadowRoot!.querySelector<UDExTooltip>("udex-tooltip")!;
    const moreBtn = this._tokenizer.shadowRoot!.querySelector<HTMLElement>(".ui5-tokenizer-more-text")!;
    tooltip.onOpen(moreBtn);
  }

  handleRemoveTag(e: Event) {
    const tag = e.target as UI5Element;
    const tagId: string = tag.getAttribute("data-id")!;
    this.handleDropdownItemPress(tagId);
    this.fireEventRemove(tagId);
  }

  handleRemoveTruncatableTag() {
    const tagId: string = this._truncatableTag.getAttribute("data-id")!;
    this.handleDropdownItemPress(tagId);
    this.closeDropdown();
    this.fireEventRemove(tagId);
  }

  handleTagPress(e: CustomEvent) {
    const tag = e.target as UDExTag;
    tag.selected = false;
    this.toggleTruncatedPopover(tag);
  }

  handleUnselectTruncatableTag() {
    if (this._truncatableTag?.selected) {
      this._truncatableTag.selected = false;
    }
  }

  handleKeydownTokenizer(e: KeyboardEvent) {
    e.stopPropagation();
    this.getTagContent(this._tags[0]).tabIndex = this._inactiveTabIndex;

    const target = e.target as Token;
    const activeIndex = this._tokenizer.tokens?.indexOf(target);
    const tagsCount = this._tokenizer._tokensCount;
    const shouldNavigateTags = (isLeft(e) || isRight(e) || isBackSpace(e) || isDelete(e)) && tagsCount > 0;
    this._tokenizer.expanded = shouldNavigateTags;

    if (isLeft(e) && activeIndex !== 0) {
      this.focusTag(activeIndex - 1);
      this.scrollToTag(activeIndex - 1);
    }

    if (isRight(e) && tagsCount !== activeIndex + 1) {
      this.focusTag(activeIndex + 1);
      this.scrollToTag(activeIndex + 1);
    }

    if ((isDown(e) || isUp(e))) {
      this._openedByTrigger = false;
      this.openDropdown();
    }
  }

  handleKeyDownTrigger(e: KeyboardEvent) {
    if (isSpace(e) || isEnter(e)) {
      e.preventDefault();
      e.stopPropagation();
      this._showTagsDropdownContent = false;
      this._openedByTrigger = true;
      this.openDropdown();
    }
  }

  handleKeyDownTag(e: KeyboardEvent) {
    const tag = e.target as UDExTag;
    if (isSpace(e) || isShift(e) || isEnter(e)) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (tag.isTruncatable && this.validTruncatableKey(e)) {
      this.toggleTruncatedPopover(tag);
    } else {
      this._showTagsDropdownContent = false;
    }

    if (isDelete(e) || isBackSpace(e)) {
      this.handleRemoveTag(e);
      const target = e.target as Token;
      const tags = this._tokenizer.tokens;
      const deletedTagIndex = tags.indexOf(target);
      let nextTokenIndex = deletedTagIndex + 1;
      if (this._tokenizer.tokens.length !== 1) {
        if (deletedTagIndex === tags.length - 1) {
          nextTokenIndex = deletedTagIndex - 1;
          this.focusTag(nextTokenIndex);
        } else {
          this.focusTag(nextTokenIndex);
        }
        this.scrollToTag(nextTokenIndex);
      } else {
        this._trigger.focus();
      }
    }
  }

  handleKeyDownDropdown(e: KeyboardEvent) {
    const isTabPrevPress = isTabPrevious(e) && !this._search;
    const isTabNextPress = isTabNext(e);
    const isEscapePress = isEscape(e);
    const isSearchResetTrigger = e.target === this._search;

    if ((isTabNextPress && (!isSearchResetTrigger || this.isListEmpty)) || isTabPrevPress || isEscapePress) {
      this.closeDropdown(e);
    }
  }

  handleKeyDownSearch(e: CustomEvent) {
    const keyEvent = e.detail as KeyboardEvent;

    if (isTabPrevious(keyEvent) || isEscape(keyEvent)) {
      this.closeDropdown(keyEvent);
    }
  }

  handleOutsideClick(): void {
    document.addEventListener("click", (e: MouseEvent) => {
      if (this._tokenizer?.tokens.length > 0 && e.target !== this) {
        this._tokenizer.expanded = false;
      }
    });
  }

  openDropdown() {
    this._popover.showAt(this._trigger);
    this._open = true;
    if (this.showMultiSelectedOptions) {
      this._tokenizer.expanded = true;
    }
  }

  closeDropdown(e?: KeyboardEvent) {
    if (this._popover.open) {
      if (this._tokenizer) {
        this._tokenizer.expanded = false;
      }

      if (e && (isEscape(e) || isTabPrevious(e))) {
        e.preventDefault();
        if (this._openedByTrigger) {
          this._trigger.focus();
        } else {
          this.focusTag(0);
        }
      }
      this._popover.close();
    }
  }

  toggleTruncatedPopover(tag: UDExTag) {
    if (!tag.isTruncatable) {
      this._showTagsDropdownContent = false;
    }

    if (tag.isTruncatable && !this._open) {
      this._truncatableTag = tag;
      this._truncatableTag.selected = true;
      this._showTagsDropdownContent = true;
      this.openDropdown();
    }
  }

  scrollToTag(index: number) {
    this._tokenizer._scrollToToken(this._tokenizer.tokens[index]);
  }

  focusTag(index: number) {
    this._tokenizer.tokens[index].focus();
    this._tokenizer.tokens[index].forcedTabIndex = "0";
  }

  fireEventRemove(id: string) {
    this.fireEvent("remove", {
      item: this.getItemFromList(id),
    });
  }

  handleOpenMultiSelect() {
    if (this._popover.open) {
      this.closeDropdown();
    } else {
      this._showTagsDropdownContent = false;
      this.openDropdown();
    }
  }

  handleOpenSingleSelect() {
    if (this._popover.open) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  }

  getHandleMultiSelectOpen() {
    return !this.isDisabled && this.modeMultiSelect ? this.handleOpenMultiSelect() : undefined;
  }

  getHandleSingleSelectOpen() {
    return !this.isDisabled && this.modeSingleSelect ? this.handleOpenSingleSelect() : undefined;
  }

  getHandlerShowMoreItems() {
    return this.displayonly ? this.showMoreReadOnlyItems() : this.showMoreItems();
  }

  getPopover(): ResponsivePopover {
    return this.shadowRoot!.querySelector<ResponsivePopover>("[ui5-popover]")!;
  }

  getSearch(): UDExSearch {
    return this.shadowRoot!.querySelector<UDExSearch>("udex-search")!;
  }

  getTrigger(): HTMLElement {
    return this.shadowRoot!.querySelector(".udex-text-field__input")!;
  }

  getTokenizer(): Tokenizer {
    return this.shadowRoot!.querySelector<Tokenizer>("[ui5-tokenizer]")!;
  }

  getTags(): Array<UDExTag> {
    return Array.from(this.shadowRoot!.querySelectorAll<UDExTag>("udex-tag"));
  }

  getTagContent(tag: UDExTag): HTMLElement {
    return tag.shadowRoot!.querySelector(".udex-tag")!;
  }

  handleDirection() {
    const directionStyles: string = window
      .getComputedStyle(this)
      .getPropertyValue("direction");

    if (!directionStyles) {
      this._direction = "ltr";
    } else {
      this._direction = directionStyles;
    }
  }

  validTruncatableKey(e: KeyboardEvent): boolean {
    return isSpace(e) || isEnter(e);
  }

  resetTagsTabIndexes() {
    this._tags.forEach((tag, i) => { this.getTagContent(tag).tabIndex = (i === 0 && !this.isDisabled) ? this._activeTabIndex : this._inactiveTabIndex; });
  }

  get allowTargetOverlap(): boolean {
    return !this.disableTargetOverlap;
  }

  get isDisabled(): boolean {
    return this.readonly || this.displayonly || this.disabled;
  }

  get overflowsTags() {
    return this._tokenizer?.tokens?.filter(tag => tag.overflows) || [];
  }

  get truncatableTagLabel(): string {
    return this._truncatableTag.text.replace(/<\/?b>/gi, "");
  }

  get showSingleSelectedOption(): boolean {
    return this._singleSelectedOption?.length > 0 && this.modeSingleSelect;
  }

  get showMultiSelectedOptions(): boolean {
    return this.hasSelectedItems && this.modeMultiSelect;
  }

  get labelClass(): string {
    const baseClass = "udex-text-field__label";
    const isActive = this._open || this._singleSelectedOption || this.hasSelectedItems;
    return isActive ? `${baseClass} ${baseClass}_active` : `${baseClass}`;
  }

  get isReadOrDisplayOnly(): boolean {
    return this.readonly || this.displayonly;
  }

  get tokenizerClass(): string {
    return `udex-text-field__tokenizer
    ${this.displayonly ? "udex-text-field__tokenize_displayonly" : ""}
    ${this.readonly ? "udex-text-field__tokenize_readonly" : ""}`;
  }

  get tokenizerExpanded() {
    if (this._showTagsDropdownContent && this._truncatableTag) {
      return false;
    }

    if (this._tokenizer) {
      return this._tokenizer.expanded;
    }

    return this._open;
  }

  get showTrigger(): boolean {
    return !this.displayonly;
  }

  get hasSelectedItems(): boolean {
    return this.selectedItems.length > 0;
  }

  get effectiveTabIndex(): string {
    const tabindex = this.getAttribute("tabindex");
    return this.disabled ? `${this._inactiveTabIndex}` : tabindex || `${this._inactiveTabIndex}`;
  }

  get triggerTabIndex(): string {
    return this.disabled || this.readonly ? `${this._inactiveTabIndex}` : "";
  }

  get iconAccessibleNameText(): string {
    return UDExSelectBox.i18nBundle.getText(SELECT_OPTIONS);
  }

  get additionalClassName(): string {
    return `udex-list__sticky`;
  }

  get searchableList() {
    return executeTemplate(UDExSelectBox.searchableListBaseTemplate, this);
  }

  get accInfo() {
    return {
      "ariaLabel": this.accessibleName || this.label || undefined,
      "ariaLabelledBy": this.accessibleNameRef || undefined,
      "ariaControls": this.shadowRoot?.querySelector("udex-list")?.getAttribute("id"),
    };
  }

  static get searchableListBaseTemplate() {
    return SearchableListBaseTemplate;
  }
}

UDExSelectBox.define();

export default UDExSelectBox;
