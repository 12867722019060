/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Avatar from "../../Avatar.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Avatar, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-avatar", tags, suffix)} class="${ifDefined(this.udexAvatarClass)}" ?disabled="${this.disabled}" initials="${ifDefined(this.initials)}" ?interactive="${this.interactive}" accessible-name="${ifDefined(this.avatarAccessibleName)}" color-scheme="${ifDefined(this.getUI5AvatarColorScheme)}" fallback-icon="${ifDefined(this.fallbackIcon)}" icon="${ifDefined(this.icon)}" shape="${ifDefined(this.shape)}" size="${ifDefined(this.size)}" @click="${this.handleClick}">${ this.imageSlot ? block1.call(this, context, tags, suffix) : undefined }${ this.badgeSlot ? block2.call(this, context, tags, suffix) : undefined }</${scopeTag("ui5-avatar", tags, suffix)}>` : html`<ui5-avatar class="${ifDefined(this.udexAvatarClass)}" ?disabled="${this.disabled}" initials="${ifDefined(this.initials)}" ?interactive="${this.interactive}" accessible-name="${ifDefined(this.avatarAccessibleName)}" color-scheme="${ifDefined(this.getUI5AvatarColorScheme)}" fallback-icon="${ifDefined(this.fallbackIcon)}" icon="${ifDefined(this.icon)}" shape="${ifDefined(this.shape)}" size="${ifDefined(this.size)}" @click="${this.handleClick}">${ this.imageSlot ? block1.call(this, context, tags, suffix) : undefined }${ this.badgeSlot ? block2.call(this, context, tags, suffix) : undefined }</ui5-avatar>`;}
function block1 (this: Avatar, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<slot></slot>`;}
function block2 (this: Avatar, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<slot name="badge" slot="badge"></slot>`;}


export default block0;