import UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import ResizeHandler, { ResizeObserverCallback } from "@ui5/webcomponents-base/dist/delegate/ResizeHandler.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";
import {
  COUNTRY_SELECT, READ_ONLY, VALUE_STATE_ERROR, VALUE_STATE_INFORMATION, VALUE_STATE_SUCCESS, VALUE_STATE_WARNING,
} from "./generated/i18n/i18n-defaults.js";
import { Country, CountryListDB } from "./data/CountryListISO3166.js";
import UDExDropdown from "./Dropdown.js";
import UDExList from "./List.js";
import UDExListItem from "./ListItem.js";
import UDExButton from "./Button.js";
import UDExStatusMessage from "./StatusMessage.js";

import "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/slim-arrow-up.js";
import "@ui5/webcomponents-icons/dist/slim-arrow-down.js";

import PhoneNumberFieldTemplate from "./generated/templates/PhoneNumberFieldTemplate.lit.js";

// Styles
import PhoneNumberFieldCss from "./generated/themes/PhoneNumberField.css.js";

export interface ExtendedCountriesListPhoneNumberData extends Country {
  selected?: boolean;
}

export enum OpenButtonIcon {
  iconUp = "slim-arrow-up",
  iconDown = "slim-arrow-down",
}

export enum FieldState {
  Standard = "Standard",
  Success = "Success",
  Error = "Error",
}

const initialPlaceholder: string = "+X XXX-XXX-XXXX";

/**
 * @class
 *
 * @constructor
 * @extends UI5Element
 * @tagname udex-phone-number-field
 * @public
 */
@customElement({
  tag: "udex-phone-number-field",
  renderer: litRender,
  styles: PhoneNumberFieldCss,
  template: PhoneNumberFieldTemplate,
  dependencies: [
    UDExDropdown,
    UDExList,
    UDExListItem,
    UDExButton,
    UDExStatusMessage,
  ],
})
/**
   * Fired when the value of the component changes at each keystroke.
   *
   * @public
   */
@event("input")
/**
   * Fired when the input operation has finished by pressing Enter or on focusout.
   *
   * @public
   */
@event("change")
class UDExPhoneNumberField extends UI5Element {
  /**
   * Defines label text for the textarea field.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    label!: string;

  /**
   * Defines the value state of the component.
   *
   * @default "Standard"
   * @public
   */
  @property({ type: FieldState, defaultValue: FieldState.Standard })
    fieldState!: `${FieldState}`;

  /**
   * Defines supporting text under the input field.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    supportingText!: string;

  /**
   * Defines whether the component is required.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    required!: boolean;

  /**
   * Defines whether the component is in disabled state.
   * <br><br>
   * <b>Note:</b> A disabled component is completely noninteractive.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    disabled!: boolean;

  /**
   * Defines whether the component is read-only.
   * <br><br>
   * <b>Note:</b> A read-only component is not editable,
   * but still provides visual feedback upon user interaction.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    readonly!: boolean;

  /**
   * Defines whether the component is display-only.
   * <br><br>
   * <b>Note:</b> A display-only component is not editable,
   * but still provides visual feedback upon user interaction, input border is not visible.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    displayonly!: boolean;

  /**
   * Defines the country iso code selected by the user
   * <br><br>
   * Note: should be used two-letter country codes, e.g.: "DE" | "PL" | "UA" | "US"
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    selectedIso!: string;

  /**
   * Defines the autoselected country iso code
   *
   * @default ""
   * @private
   */
  @property({ type: String, noAttribute: true, defaultValue: "" })
    autoSelectedIso!: string;

  /**
   * Allows to define the list of blocked country.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    blockedCountryCodes?: string;

  /**
   * Allows control over the height.
   *
   * @public
   */
  @property({ type: String })
    height?: string;

  /**
   * Defines the value of the component.
   *
   * @default ""
   */
  @property({ type: String, defaultValue: "" })
    value!: string;

  /**
   * Defines the open button icon
   *
   * @private
   */
  @property({ type: String, noAttribute: true, defaultValue: OpenButtonIcon.iconDown })
    buttonIcon!: string;

  /**
   * Defines the open button icon
   *
   * @private
   */
  @property({ type: Boolean, noAttribute: true })
    _dropdownExpanded!: boolean;

  /**
   * Defines the phone format of the input
   *
   * @private
   */
  @property({ type: String, defaultValue: initialPlaceholder, noAttribute: true })
    phoneFormat!: string;

  /**
   * Defines the maxlength of the input
   *
   * @private
   */
  @property({ validator: Integer })
    maxlength!: number;

  /**
   * Defines the accessible ARIA name of the component.
   *
   * @default ""
   * @public
   */
  @property()
    accessibleName!: string;

  /**
   * Receives id (or many ids) of the elements that label the component.
   * @default ""
   * @public
   */
  @property({ defaultValue: "" })
    accessibleNameRef!: string;

  /**
   * Defines the allowed keys for the input
   *
   * @private
   */
  @property({ type: String, multiple: true, noAttribute: true })
    allowedKeys: string[] = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Enter",
      "Home",
      "End",
      "Escape",
    ];

  /**
   * Keeps the value of previously pressed key
   *
   * @private
   */
  @property({ type: String, multiple: false, noAttribute: true })
    previousKey!: string;

  /**
   * Defines the active (focus)
   * @default false
   * @private
   */
  @property({ type: Boolean })
    active!: boolean;

  /**
   * Defines is it the touch of the input field (only for mobile devices).
   * @default false
   * @private
   */
  @property({ type: Boolean })
    _isTouched!: boolean;

  static i18nBundle: I18nBundle;
  _dropdown!: UDExDropdown;
  _handleResizeBound: ResizeObserverCallback;

  static async onDefine() {
    UDExPhoneNumberField.i18nBundle = await getI18nBundle("@udex/web-components");
  }

  get countrySelectorAriaLabel() {
    return this.accessibleName || `${UDExPhoneNumberField.i18nBundle.getText(COUNTRY_SELECT)} ${this.label}`.trim();
  }

  get countryIso() {
    return this.selectedIso || this.autoSelectedIso;
  }

  countriesList: ExtendedCountriesListPhoneNumberData[] = CountryListDB;

  constructor() {
    super();
    this._handleResizeBound = this._handleResize.bind(this);
  }

  onEnterDOM(): void {
    ResizeHandler.register(this, this._handleResizeBound);
  }

  onExitDOM(): void {
    ResizeHandler.deregister(this, this._handleResizeBound);
  }

  onAfterRendering() {
    this._dropdown = this.getDropdown();
  }

  onClick(): void {
    if (this.isButtonDisabled) {
      return;
    }
    const button = this.getButton();
    const dropdown = this.getDropdown();

    if (dropdown.open) {
      dropdown.onClose();
    } else {
      dropdown.onOpen(button);
    }
  }

  _handleResize() {
    this._dropdown.style.setProperty("--udex-dropdown-pane-width", `${this.offsetWidth}px`);
  }

  handleInput(e: InputEvent) {
    this.value = (e.target as HTMLInputElement)?.value.trim();

    this.applyPhoneMask();

    if (!this.value.startsWith(this.selectedPhonePrefix)) {
      this.selectedIso = "";
    }

    if (this.value.length === 0 || this.value === "+") {
      this.autoSelectedIso = "";
      this.selectedIso = "";
    }

    this.fireEvent("input");
  }

  handleChange() {
    this.fireEvent("change");
  }

  handleKeyDown = (e: KeyboardEvent) => {
    const isKeyAllowed = e.key.match(/\d/)
      || this.allowedKeys.includes(e.key)
      || e.metaKey
      || e.ctrlKey;

    if (!isKeyAllowed) {
      e.preventDefault();
    }

    e.stopPropagation();
    this.previousKey = e.key;
  };

  handleKeyUp() {
    this.value = this.value.replace(/[^\d+ ()-]/g, "").trim();
    this.applyPhoneMask();
  }

  handleFocus() {
    this.active = true;
    if (this.countryIso.length === 0) {
      this.phoneFormat = initialPlaceholder;
    }

    if (this.value.length === 0 || this.value === "+") {
      this.value = this.selectedPhonePrefix;
    }
  }

  handleClick(): void {
    if (!isDesktop()) {
      this._isTouched = true;
    }
  }

  handleBlur() {
    this.active = false;
    this._isTouched = false;
    this.applyPlaceholder();

    const prefixLength = this.selectedPhonePrefix.length;
    const enteredPrefix = this.value.substring(0, prefixLength);
    const isIncorrectPrefixEntered = enteredPrefix !== this.selectedPhonePrefix;

    const isIncorrectNumberEntered = !this.countryIso || this.value.length <= prefixLength || isIncorrectPrefixEntered;

    if (isIncorrectNumberEntered) {
      this.value = "";
    }

    this.fireEvent("change");
  }

  handleSelect(e: CustomEvent) {
    const dropdown = this.getDropdown();

    dropdown.onClose();

    this.selectedIso = e.detail.item.id;
    this.value = this.selectedPhonePrefix;
    this.phoneFormat = this.value + this.selectedPlaceholder;
    this.maxlength = this.phoneFormat.length;
    this.getInputField().focus();
  }

  handleDropdownOpen() {
    this.buttonIcon = OpenButtonIcon.iconUp;
    this._dropdownExpanded = true;
  }
  handleDropdownClose() {
    this.buttonIcon = OpenButtonIcon.iconDown;
    this._dropdownExpanded = false;
  }

  autoSelectCountry(value: string) {
    const countryToSelect = this.updatedCountriesList.find(country => {
      const valuePhonePrefix = value.substring(0, country.phonePrefix.length);

      return country.phonePrefix === valuePhonePrefix;
    });

    if (countryToSelect) {
      this.autoSelectedIso = countryToSelect.iso;
    }

    if (this.selectedPhonePrefix && this.selectedPlaceholder) {
      this.maxlength = this.selectedPhonePrefix.length + this.selectedPlaceholder.length;
    }
  }

  applyPhoneMask() {
    const isNewPrefixEntered = this.value.substring(0, this.selectedPhonePrefix.length) !== this.selectedPhonePrefix;
    if (this.value.split("")[0] !== "+") {
      this.value = `+${this.value}`;
    }

    this.autoSelectCountry(this.value);

    if (isNewPrefixEntered) {
      return;
    }

    const mask = this.selectedPhonePrefix + this.selectedPlaceholder;
    const maskSymbols = [" ", "-", "(", ")"];

    const valueWithMask = this.value.split("").map((char, index) => {
      if (maskSymbols.includes(char) && char === mask[index]) {
        return char;
      }
      if (maskSymbols.includes(char) && char !== mask[index]) {
        return "";
      }
      if (maskSymbols.includes(mask[index]) && char !== mask[index]) {
        return `${mask[index]}${char}`;
      }
      return char;
    }).join("");

    this.value = valueWithMask;

    if (this.value.length > this.maxlength) {
      this.value = this.value.substring(0, this.maxlength);
    }
  }

  applyPlaceholder() {
    this.phoneFormat = this.countryIso.length > 0
      ? this.selectedPhonePrefix + this.selectedPlaceholder : initialPlaceholder;
  }

  getDropdown() {
    return this.shadowRoot!.querySelector<UDExDropdown>(
      "[udex-dropdown]",
    )!;
  }

  getButton() {
    return this.shadowRoot!.querySelector<UDExButton>(
      "[udex-button]",
    )!;
  }

  getInputField() {
    return this.shadowRoot!.querySelector<HTMLFormElement>(
      "input",
    )!;
  }

  get selectedPhonePrefix(): string {
    if (!this.countryIso) {
      return "";
    }

    return this.updatedCountriesList.filter(country => country.iso === this.countryIso)[0]?.phonePrefix
    || this.updatedCountriesList[0]?.phonePrefix;
  }

  get selectedPlaceholder(): string {
    if (!this.countryIso) {
      return "";
    }

    return this.updatedCountriesList.filter(country => country.iso === this.countryIso)[0]?.phoneFmt
      || this.updatedCountriesList[0]?.phoneFmt;
  }

  get selectedCountryName(): string {
    if (!this.countryIso) {
      return "";
    }

    return this.updatedCountriesList.filter(country => country.iso === this.countryIso)[0]?.countryName
      || this.updatedCountriesList[0]?.countryName;
  }

  get selectedFlagBackgroundPosition() {
    if (!this.countryIso) {
      return {};
    }

    const selectedCountry = this.updatedCountriesList.filter(country => country.iso === this.countryIso)[0];

    if (!selectedCountry) {
      return {};
    }

    return { "backgroundPosition": `${selectedCountry.flagX} ${selectedCountry.flagY}` };
  }

  get ariaInvalid() {
    return this.fieldState === FieldState.Error ? "true" : null;
  }

  get isInputReadonly() {
    return this.readonly || this.displayonly;
  }

  get isButtonDisabled() {
    return this.disabled || this.isInputReadonly;
  }

  get updatedCountriesList(): ExtendedCountriesListPhoneNumberData[] {
    return this.countriesList.map(country => {
      if (country.selected) {
        delete country.selected;
      }

      if (country.iso === this.countryIso) {
        country.selected = true;
      }

      return country;
    });
  }

  get valueStateTypeMappings() {
    return {
      "Success": UDExPhoneNumberField.i18nBundle.getText(VALUE_STATE_SUCCESS),
      "Standard": UDExPhoneNumberField.i18nBundle.getText(VALUE_STATE_INFORMATION),
      "Error": UDExPhoneNumberField.i18nBundle.getText(VALUE_STATE_ERROR),
      "Warning": UDExPhoneNumberField.i18nBundle.getText(VALUE_STATE_WARNING),
    };
  }

  get hasValueState() {
    return this.fieldState !== FieldState.Standard;
  }

  get valueStateText() {
    return this.fieldState !== FieldState.Standard ? this.valueStateTypeMappings[this.fieldState] : undefined;
  }

  get readOnlyStateText() {
    return UDExPhoneNumberField.i18nBundle.getText(READ_ONLY);
  }

  get ariaDescribedById(): string {
    return `${this.supportingTextId} ${this.valueStateTextId} ${this.readOnlyStateTextId}`.trim();
  }

  get supportingTextId():string {
    return this.supportingText ? `${this._id}-supportingTextDesc` : "";
  }

  get valueStateTextId():string {
    return this.hasValueState ? `${this._id}-valueStateDesc` : "";
  }

  get readOnlyStateTextId():string {
    return this.readonly ? `${this._id}-stateReadOnlyDesc` : "";
  }

  onBeforeRendering() {
    this.applyPlaceholder();

    if (this.blockedCountryCodes?.length) {
      const blockedCountryCodesArray = this.blockedCountryCodes.split(",").map(code => code.trim().toUpperCase());
      this.countriesList = this.countriesList
        .filter(country => !blockedCountryCodesArray?.includes(country.iso.toUpperCase()));
    }
  }
}

UDExPhoneNumberField.define();

export default UDExPhoneNumberField;
