import UI5Element, { ChangeInfo } from "@ui5/webcomponents-base/dist/UI5Element.js";
import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import slot from "@ui5/webcomponents-base/dist/decorators/slot.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import Integer from "@ui5/webcomponents-base/dist/types/Integer.js";
import ResizeHandler, { ResizeObserverCallback } from "@ui5/webcomponents-base/dist/delegate/ResizeHandler.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import { getFeature } from "@ui5/webcomponents-base/dist/FeaturesRegistry.js";
import Popover from "@ui5/webcomponents/dist/Popover.js";
import {
  isEnter,
  isTabNext,
  isTabPrevious,
  isUp,
  isDown,
  isEscape,
  isSpace,
} from "@ui5/webcomponents-base/dist/Keys.js";
import { isDesktop } from "@ui5/webcomponents-base/dist/Device.js";
import List from "./List.js";
import ListItemBase from "./ListItemBase.js";
import "@ui5/webcomponents/dist/Icon.js";
import "@ui5/webcomponents-icons/dist/decline.js";
import "@ui5/webcomponents-icons/dist/search.js";
import "@ui5/webcomponents-icons/dist/microphone.js";
import "@ui5/webcomponents-icons/dist/slim-arrow-down.js";
import debounce from "./utils/debounce.js";
import {
  SUGGESTED_TEXT,
  RECOMMENDED_TEXT,
  AVAILABLE_VALUES,
  NO_MATCHES_FOUND,
  ACCESSIBLE_NAME_RESET_ICON,
  ACCESSIBLE_NAME_SEARCH_ICON,
  TOOLTIP_RESET_BUTTON,
  TOOLTIP_VOICE_BUTTON,
  TOOLTIP_SUBMIT_BUTTON,
} from "./generated/i18n/i18n-defaults.js";
import type SearchInputSuggestion from "./features/SearchSuggestionsPopover.js";

import SearchTemplate from "./generated/templates/SearchTemplate.lit.js";
import SearchSuggestionsTemplate from "./generated/templates/SearchSuggestionsTemplate.lit.js";

// Styles
import SearchCss from "./generated/themes/Search.css.js";
import SearchSuggestionsCss from "./generated/themes/SearchSuggestions.css.js";
import retryUntilSuccessful from "./utils/retryUntilSuccessful.js";

const SearchIcon: string = "sap-icon://search";

const SuggestionsPopoverId: string = "suggestions-popover";

type ListItem = {
  label: string;
  icon: string;
}

type DataList = Array<ListItem | string>;

export type SuggestionData = {
  label: string,
  list: DataList
}

export type RecommendData = {
  label: string,
  list: DataList
}

type SuggestionRecommendLists = {
  suggested: SuggestionData | DataList,
  recommended: RecommendData | DataList
}

export type SuggestionList = {
  handleSelectItem: () => void,
  text: string,
  icon: string,
  title: string,
  hasCustomIcon: boolean,
  id: string
}

export enum Mode {
  Standard = "Standard",
  HomeSearch = "Home Search"
}

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-search</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/Search.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UI5Element
 * @tagname udex-search
 * @public
 */
@customElement({
  tag: "udex-search",
  renderer: litRender,
  styles: SearchCss,
  template: SearchTemplate,
  staticAreaStyles: SearchSuggestionsCss,
  staticAreaTemplate: SearchSuggestionsTemplate,
  get dependencies() {
    const Suggestion = getFeature<typeof SearchInputSuggestion>("SearchInputSuggestion");
    return ([Popover] as Array<typeof UI5Element>).concat(Suggestion ? [...Suggestion.dependencies, List] : [List]);
  },
})

/**
 * Fired event when the user submitted the value.
 *
 * @event sap.ui.webc.web-components.UDExSearch#submitSearchValue
 * @public
 */
@event("submit-search-value", { detail: { value: { type: String } } })
/**
 * Fired event when the user typed to search field.
 *
 * @event sap.ui.webc.web-components.UDExSearch#handleValueSearch
 * @public
 */
@event("handle-value-search", { detail: { value: { type: String } } })
/**
 * Fired event when the user clicks on the reset button.
 *
 * @event sap.ui.webc.web-components.UDExSearch#handleResetValue
 * @public
 */
@event("handle-value-reset")
/**
 * Fired event when the user presses escape in search field.
 *
 * @event sap.ui.webc.web-components.UDExSearch#handleCloseSearch
 * @public
 */
@event("handle-close-search")
/**
 * Fired event when the user clicks on the item inside the popover.
 *
 * @event sap.ui.webc.web-components.UDExSearch#dropdownItemClick
 * @public
 */
@event("dropdown-item-click", { detail: { value: { type: String } } })
/**
 * Fired event when the user clicks on the intem inside the prefix dropdown.
 *
 * @event sap.ui.webc.web-components.UDExSearch#prefixItemClick
 * @public
 */
@event("prefix-item-click", { detail: { item: { type: ListItemBase } } })
class UDExSearch extends UI5Element {
  static i18nBundle: any;
  _labelActiveClassName = "udex-search__label--active";
  _labelAnimationClassName = "udex-search__label--animation-enabled";
  _voiceActiveClassName = "udex-search__voice-button--active";
  _focusActiveClassName = "udex-search__container--focus";
  _handleResizeBound: ResizeObserverCallback;
  suggestedList!: Array<SuggestionList>;
  recommendedList!: Array<SuggestionList>;
  speechRecognition!: any;
  isNotMozillaFirefox!: boolean;
  isNotMozillaFirefoxIOS!: boolean;
  isFocusedOnTextfield!: boolean;
  Suggestion?: SearchInputSuggestion;
  /**
   * Defines the value of the component.
   *
   * @default ""
   * @formEvents input
   * @formProperty
   * @public
   */
  @property({ type: String })
    value!: string;

  /**
   * Defines label text for the input field.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    label!: string;

  /**
   * Defines if the label animation is disabled for the search field.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    labelAnimationDisabled!: boolean;

  /**
   * Defines display the reset button for the search field.
   *
   * @default false
   * @private
   */
  @property({ type: Boolean, noAttribute: true })
    showResetButton!: boolean;

  /**
   * Defines display the submit button for the search field.
   *
   * @default true
   * @public
   */
  @property({ type: Boolean })
    showSubmitButton!: boolean;

  /**
   * Defines non-interactive the submit button for the search field.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    nonInteractiveSubmitButton!: boolean;

  /**
   * Defines display the voice button for the search field.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    showVoiceButton!: boolean;

  /**
   * Defines suggestions and recommended lists for the component.
   * <br><br>
   * <b>Note:</b> Structure of the property:
   * { suggested: ["string", string, ...], recommended: ["string", "string", ...] }
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    suggestionList!: string;
  /**
   * Width of the search component
   * @private
   */
  @property({ validator: Integer })
    _searchWidth?: number;

  /**
   * Define the label of the prefix for the dropdown.
   *
   * @private
   */
  @property({ type: String })
    labelPrefix!: string;

  /**
   * Defines display the suggestion popover in the component.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    showSuggestedPopover!: boolean;

  /**
   * Defines the language of voice search.
   *
   * @default "en-US"
   * @public
   */
  @property({ type: String, defaultValue: "en-US" })
    languageVoice!: string;

  /**
   * Defines the mode of component.
   * <br><br>
   * <b>Note:</b> accepts a string "Standard" or "Home Search".
   *
   * @default "Standard"
   * @public
   */
  @property({ type: String, defaultValue: Mode.Standard })
    mode!: Mode;

  /**
   * Defines open suggestion popover when clicked on the input field.
   *
   * @defaultvalue false
   * @public
   */
  @property({ type: Boolean })
    showPopoverWhenClickedIntoInput!: boolean;

  /**
   * Defines open suggestion popover.
   *
   * @defaultvalue false
   * @public
   */
  @property({ type: Boolean })
    openSuggestionPopup!: boolean;

  /**
   * Defines the accessible ARIA name of the component.
   *
   * @default ""
   * @public
   */
  @property()
    accessibleName!: string;

  /**
   * Defines the accessible ARIA name of the reset icon.
   *
   * @default "Clear"
   * @public
   */
  @property({ type: String })
    accessibleNameResetIcon!: string;

  /**
   * Defines the accessible ARIA name of the search icon (applied just when "nonInteractiveSubmitButton" is active).
   *
   * @default "Search"
   * @public
   */
  @property({ type: String })
    accessibleNameSearchIcon!: string;

  /**
   * Defines the tooltip of the clear button.
   *
   * @default "Clear"
   * @public
   */
  @property({ type: String })
    tooltipResetButton!: string;

  /**
   * Defines the tooltip of the voice button.
   *
   * @default "Voice"
   * @public
   */
  @property({ type: String })
    tooltipVoiceButton!: string;

  /**
   * Defines the tooltip of the submit button.
   *
   * @default "Search"
   * @public
   */
  @property({ type: String })
    tooltipSubmitButton!: string;

  /**
   * Defines the text if the suggestion list doesn't return results.
   *
   * @default "No matches found."
   * @public
  */
  @property({ type: String, defaultValue: "No matches found." })
    noResultText!: string;

  /**
   * Defines the inner stored class of the label.
   *
   * @default ""
   * @private
   */
  @property({ type: String, noAttribute: true, defaultValue: "" })
    labelActiveClass!: string;

  /**
   * Defines the inner stored animation class of the label.
   *
   * @default ""
   * @private
   */
  @property({ type: String, noAttribute: true, defaultValue: "" })
    labelAnimationClass!: string;

  /**
   * Defines the voice active class of the button.
   *
   * @default ""
   * @private
   */
  @property({ type: String, noAttribute: true, defaultValue: "" })
    voiceActiveClass!: string;

  /**
   * Defines the inner stored value of the classes of the label.
   *
   * @default ""
   * @private
   */
  @property({ type: String, noAttribute: true, defaultValue: "" })
    labelClasses!: string;

  /**
   * Defines the class of focus on the input field.
   *
   * @default ""
   * @private
   */
  @property({ type: String, noAttribute: true, defaultValue: "" })
    focusClasses!: string;

  /**
   * Defines the suggestion popover is opened
   *
   * @default false
   * @private
   */
  @property({ type: Boolean, noAttribute: true })
    open!: boolean;

  /**
   * The label of the suggestion section.
   * @private
   */
  @property({ type: String })
    _suggestionLabel?: string;

  /**
   * The label of the recommend section.
   * @private
   */
  @property({ type: String })
    _recommendLabel?: string;

  /**
   * The console warning shown after all unsuccessful attempts of opening SearchSuggestionsPopover component.
   * @default "UdexSearchSuggestionsPopover couldn`t be opened"
   */
  @property({ type: String, defaultValue: "UdexSearchSuggestionsPopover couldn`t be opened" })
    unsuccessfulRetryMessage: string = "UdexSearchSuggestionsPopover couldn`t be opened";

  /**
   * The delay before retrying to open the SerchSuggestionsPopover component.
   * @default 200
   */
  @property({ type: Number, defaultValue: 200 })
    retryDelay: number = 200;

  /**
   * The maximum number of attempts for opening the SearchSuggestionsPopover component.
   * @default 5
   */
  @property({ type: Number, defaultValue: 5 })
    maxRetryAttempts: number = 5;

  /**
   * Defines the prefix dropdown of the Search component.
   *
   * @slot dropdownPrefix
   * @public
   */
  @slot({ type: HTMLElement, "default": true })
    dropdownPrefix!: Array<List>;

  /**
   * Defines the open button icon
   *
   * @private
   */
  @property({ type: Boolean, noAttribute: true })
    _prefixDropdownExpanded!: boolean;

  /**
   * Defines the active (focus)
   * @default false
   * @private
   */
  @property({ type: Boolean })
    active!: boolean;
  /**
   * Defines is it the touch of the input field (only for mobile devices).
   * @default false
   * @private
   */
  @property({ type: Boolean })
    _isTouched!: boolean;

  constructor() {
    super();
    this._handleResizeBound = this._handleResize.bind(this);
  }

  static async onDefine(): Promise<void> {
    UDExSearch.i18nBundle = await getI18nBundle("@udex/web-components");
  }

  onEnterDOM(): void {
    this.isNotMozillaFirefox = !this.isMozillaFirefox("Firefox");
    this.isNotMozillaFirefoxIOS = !this.isMozillaFirefox("FxiOS");
    this.initSpeechRecognition();
    ResizeHandler.register(this, this._handleResizeBound);
  }

  onExitDOM(): void {
    ResizeHandler.deregister(this, this._handleResizeBound);
    this.removeEventListener("dropdown-trigger-event", () => { });
  }

  initSpeechRecognition(): void {
    try {
      if (this.isNotMozillaFirefox || this.isNotMozillaFirefoxIOS) {
        const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition || (window as any).mozSpeechRecognition || (window as any).msSpeechRecognition;
        this.speechRecognition = new SpeechRecognition();
        this.speechRecognition.continuous = true;
        this.speechRecognition.lang = this.languageVoice;
        this.speechRecognition.interimResults = false;
        this.speechRecognition.maxAlternatives = 5;
      }
    } catch (error: any) {
      console.warn("The voice button does not work in this browser");
    }
  }

  private isMozillaFirefox(keyWord: string) {
    const userAgent = navigator.userAgent;
    const firefoxAgent = userAgent.split(" ").find(item => item.indexOf(keyWord) > -1);

    if (firefoxAgent) {
      const splitAgent = firefoxAgent.split("/");
      const firefoxVersion = splitAgent[splitAgent.length - 1];
      return Number(firefoxVersion) >= 125;
    }
    return false;
  }

  onInvalidation(changeInfo: ChangeInfo): void {
    this.setNewValue(changeInfo);
    this.setPopoverState(changeInfo);
    this.setNewSuggestionList(changeInfo);
  }

  setNewValue(changeInfo: ChangeInfo): void {
    if (changeInfo.name === "value") {
      this.value = changeInfo.newValue as string;
      this.setSuggestedRecommendedLists(this.suggestionList);
      this.toggleLabelActiveClass();
    }
  }

  private toggleLabelActiveClass() {
    if (this.labelActiveClass && !this.labelAnimationDisabled && !this.isFocusedOnTextfield) {
      this.handleBlur();
    }
  }

  setPopoverState(changeInfo: ChangeInfo): void {
    if (changeInfo.name === "openSuggestionPopup") {
      this.handlePopoverTrigger(changeInfo.newValue as boolean);
    }
  }

  handlePopoverTrigger(isOpenPopover: boolean): void {
    if (isOpenPopover) {
      this.onOpenPopover();
    } else {
      this.onClosePopover();
    }
  }

  setNewSuggestionList(changeInfo: ChangeInfo): void {
    if (changeInfo.name === "suggestionList" && !!changeInfo.newValue) {
      this.setSuggestedRecommendedLists(changeInfo.newValue as string);
    }
  }

  setSuggestedRecommendedLists(value: string): void {
    if (this.showSuggestedPopover) {
      const parsedData: SuggestionRecommendLists = JSON.parse(value);
      const hasDataLabels = this.hasDataLabel(parsedData);

      if (this.shouldShowPopover(parsedData, hasDataLabels)) {
        this.setDropdownLabels(hasDataLabels, parsedData);
        this.suggestedList = this.getListWithSuggestion(this.getSuggestedList(hasDataLabels, parsedData), "suggested");
        this.recommendedList = this.getListWithSuggestion(this.getRecommendedList(hasDataLabels, parsedData), "recommended");
      }
      if (!this.shouldShowPopover(parsedData, hasDataLabels)) {
        this.onClosePopover();
        this.resetSuggestedRecommendedLists();
      }
    }
  }

  private hasDataLabel(parsedData: SuggestionRecommendLists) {
    return Object.values(parsedData).some(item => Object.prototype.hasOwnProperty.call(item, "label"));
  }

  private resetSuggestedRecommendedLists() {
    this.suggestedList = [];
    this.recommendedList = [];
  }

  private setDropdownLabels(hasDataLabels: boolean, parsedData: SuggestionRecommendLists) {
    this._suggestionLabel = this.getSuggestionLabel(hasDataLabels, parsedData);
    this._recommendLabel = this.getRecommendLabel(hasDataLabels, parsedData);
  }

  private getRecommendLabel(hasDataLabels: boolean, parsedData: SuggestionRecommendLists): string {
    return hasDataLabels
      ? (parsedData.recommended as RecommendData).label || ""
      : UDExSearch.i18nBundle.getText(RECOMMENDED_TEXT) as string;
  }

  private getSuggestionLabel(hasDataLabels: boolean, parsedData: SuggestionRecommendLists): string {
    return hasDataLabels
      ? (parsedData.suggested as SuggestionData).label || ""
      : UDExSearch.i18nBundle.getText(SUGGESTED_TEXT) as string;
  }

  private getRecommendedList(hasDataLabels: boolean, parsedData: SuggestionRecommendLists) {
    return hasDataLabels
      ? (parsedData.recommended as RecommendData).list
      : parsedData.recommended as DataList;
  }

  private getSuggestedList(hasDataLabels: boolean, parsedData: SuggestionRecommendLists) {
    return hasDataLabels
      ? (parsedData.suggested as SuggestionData).list
      : parsedData.suggested as DataList;
  }

  private shouldShowPopover(parsedData: SuggestionRecommendLists, hasDataLabels: boolean) {
    const hasSuggestedListContent = this.hasSuggestedContent(hasDataLabels, parsedData);
    const hasRecommendedContent = this.hasRecommendedContent(hasDataLabels, parsedData);
    return this.showPopoverWhenClickedIntoInput || (hasSuggestedListContent || hasRecommendedContent);
  }

  private hasSuggestedContent(hasDataLabels: boolean, parsedData: SuggestionRecommendLists) {
    return hasDataLabels
      ? Object.prototype.hasOwnProperty.call(parsedData.suggested, "list") && (parsedData.suggested as SuggestionData).list.length
      : (parsedData.suggested as DataList).length;
  }

  private hasRecommendedContent(hasDataLabels: boolean, parsedData: SuggestionRecommendLists) {
    return hasDataLabels
      ? Object.prototype.hasOwnProperty.call(parsedData.recommended, "list") && (parsedData.recommended as RecommendData).list.length
      : (parsedData.recommended as DataList).length;
  }

  getListWithSuggestion(suggestedList: DataList, idName: string): Array<SuggestionList> {
    return suggestedList?.map((item, index) => {
      const itemWithCustomIcon = typeof item === "object";
      const label = itemWithCustomIcon ? item.label : item;
      return {
        handleSelectItem: this.handleSelectItem(label),
        text: this.getTextWithSuggestion(label),
        title: label,
        icon: itemWithCustomIcon ? item.icon : SearchIcon,
        hasCustomIcon: itemWithCustomIcon,
        id: `${idName}-${index}`,
      };
    });
  }

  escapeRegExp(input: string): string {
    const specialChars = /[-[\]/{}()*+?.\\^$|]/g;
    return input.replace(specialChars, "\\$&");
  }

  getTextWithSuggestion(text: string): string {
    const escapedValue = this.escapeRegExp(this.value);
    const search = new RegExp(escapedValue, "i");
    return text.replace(search, "<b>$&</b>");
  }

  handleSelectItem = (itemValue: string) => () => {
    this.value = itemValue;
    this.fireDropdownItemClick(itemValue);
    this.handleSubmitValue();
  };

  fireDropdownItemClick(value: string) {
    this.fireEvent("dropdown-item-click", { value });
  }

  handleDropdownClick() {
    const dropdown = this.getPrefixPopover();

    if (dropdown.opened) {
      this.onClosePrefixPopover();
    } else {
      this.onOpenPrefixPopover();
    }
  }

  getPrefixWrapper() {
    return this.shadowRoot!.querySelector(".udex-search__prefix") as HTMLElement;
  }

  getPrefixPopover() {
    return this.shadowRoot!.querySelector<Popover>("[ui5-popover]")!;
  }

  getPrefixButton() {
    return this.shadowRoot!.querySelector(".udex-search__prefix-button") as HTMLElement;
  }

  onOpenPrefixPopover() {
    if (this.dropdownPrefix.length) {
      const wrapper = this.getPrefixWrapper();
      const dropdown = this.getPrefixPopover();
      const button = this.getPrefixButton();

      this._prefixDropdownExpanded = true;
      dropdown.showAt(this.getPrefixButton());
      dropdown.style.width = `${button.offsetWidth + 1}px`;
      button.classList.add("udex-search__prefix-button--active");
      wrapper.classList.add("udex-search__prefix--show-popover");
      this.handleItemClick();
    }
  }

  private handleItemClick() {
    this.dropdownPrefix[0].addEventListener("item-press", (e: any) => {
      const selectedItemPrefix = e.detail.item as ListItemBase;
      this.labelPrefix = selectedItemPrefix.innerText;
      this.fireEvent("prefix-item-click", { item: selectedItemPrefix });
      this.onClosePrefixPopover();
    });
  }

  onClosePrefixPopover() {
    const dropdown = this.getPrefixPopover();

    dropdown.close();
    this._prefixDropdownExpanded = false;
    this.handleAfterClosePrefixPopover();
  }

  handleAfterClosePrefixPopover() {
    const wrapper = this.getPrefixWrapper();
    const button = this.getPrefixButton();

    wrapper.classList.remove("udex-search__prefix--show-popover");
    button.classList.remove("udex-search__prefix-button--active");
    this.dropdownPrefix[0].removeEventListener("item-press", () => {});
  }

  handleInputClick(): void {
    if (!isDesktop()) {
      this._isTouched = true;
    }
    if (this.showPopoverWhenClickedIntoInput) {
      this.onOpenPopover();
    }
  }

  onSubmit(e: MouseEvent): void {
    e.stopPropagation();
    this.handleSubmitValue();
  }

  handleSubmitValue(): void {
    this.onClosePopover();
    this.fireEvent("submit-search-value", { value: this.value });
  }

  handleAriaExpanded() {
    const value = this.open.toString();
    this.getSearchInput()?.setAttribute("aria-expanded", value);
  }

  onOpenPopover(): void {
    if (this.showSuggestedPopover) {
      if (!this.Suggestion) {
        retryUntilSuccessful(() => this.Suggestion, this.retryDelay, this.maxRetryAttempts, this.unsuccessfulRetryMessage)
          .then(() => {
            this.Suggestion!.open();
          })
          .catch(error => {
            console.warn(error.message);
          });
      } else {
        this.Suggestion.open();
      }

      this.handleClassToRoot();
      this.handleAriaExpanded();
    }
  }

  onClosePopover(): void {
    if (this.showSuggestedPopover) {
      this.Suggestion!.close();
      this.handleClassToRoot();
      this.handleAriaExpanded();
      this.openSuggestionPopup = false;
    }
  }

  handleClassToRoot() {
    const hasAdditionRootClass = !this.shadowRoot?.querySelector(".udex-search__root")?.classList.contains("udex-search__root--show-popover");

    if (this.mode === Mode.HomeSearch && hasAdditionRootClass && this.open) {
      this.shadowRoot?.querySelector(".udex-search__root")?.classList.add("udex-search__root--show-popover");
    }

    if (this.mode === Mode.HomeSearch && !hasAdditionRootClass && !this.open) {
      this.shadowRoot?.querySelector(".udex-search__root")?.classList.remove("udex-search__root--show-popover");
    }
  }

  onBeforeRendering(): void {
    if (this.showSuggestedPopover) {
      this.enableSuggestions();
    }

    if (this.isHomeSearchWithPrefixMode && this.dropdownPrefix.length) {
      const dropdownItems = Array.from(this.dropdownPrefix[0].children) as Array<ListItemBase>;
      const selectedItemIndex = dropdownItems.findIndex(listItem => listItem.selected);
      const selectedItemPrefix = selectedItemIndex >= 0 ? dropdownItems[selectedItemIndex] : dropdownItems[0];

      this.labelPrefix = selectedItemPrefix.innerText;
    }

    this.showResetButton = !!this.value.length;

    if (this.value && this.labelActiveClass === "") {
      this.labelActiveClass = this._labelActiveClassName;
    }

    if (this.mode === Mode.HomeSearch) {
      this.labelAnimationDisabled = true;
    }

    if (!this.labelAnimationDisabled) {
      this.labelAnimationClass = this._labelAnimationClassName;
    }

    this.labelClasses = `udex-search__label ${this.labelActiveClass} ${this.labelAnimationClass}`;

    if (this.isVoiceButton) {
      this.labelClasses += " udex-search__label-with-voice-icon";
    }

    if (this.isShowSubmitButton) {
      this.labelClasses += " udex-search__label-with-search-icon";
    }
  }

  enableSuggestions() {
    if (this.Suggestion) {
      return;
    }

    const Suggestion = getFeature<typeof SearchInputSuggestion>("SearchInputSuggestion");

    if (Suggestion) {
      this.Suggestion = new Suggestion(this);
    } else {
      throw new Error("You have to import '@udex/webcomponents/dist/features/SearchSuggestionsPopover.js' module to use udex-search suggestions");
    }
  }

  onAfterRendering(): void {
    if (this.showSuggestedPopover) {
      this.handleOutsideClick();
      this.handleOpenSuggestionPopover();
      this.setSearchWidth();
    }
  }

  async handleOpenSuggestionPopover() {
    if (this.openSuggestionPopup) {
      await this.Suggestion?._getSuggestionPopover().then(() => {
        this.handlePopoverTrigger(this.openSuggestionPopup);
      });
    }
  }

  handleOutsideClick(): void {
    document.addEventListener("mousedown", (e: MouseEvent) => {
      const popover = (e.target as HTMLElement).shadowRoot?.querySelector("[ui5-popover]");

      if (e.target !== this && popover !== this.Suggestion?.popover) {
        this.handleBlur();
        this.onClosePopover();
      }
    });
  }

  handleBlur(): void {
    this._isTouched = false;
    if (this.value) {
      this.labelActiveClass = this._labelActiveClassName;
    } else {
      this.labelActiveClass = "";
    }
  }

  handleSearchChange(e: InputEvent): void {
    this.value = (e.target as HTMLInputElement)?.value;
    this.showResetButton = !!this.value.length;
    this.handleDebounce();
  }

  handleDebounce = debounce(() => {
    this.fireEvent("handle-value-search", { value: this.value });
    if (this.showSuggestedPopover && this.value.length >= 2) {
      this.onOpenPopover();
    }
    if (!this.showPopoverWhenClickedIntoInput && this.showSuggestedPopover && this.value.length < 2) {
      this.onClosePopover();
    }
  });

  handleKeyDown = (e: KeyboardEvent) => {
    e.stopPropagation();
    this.handleEnterKey(e);
    this.handleTabKey(e);
    this.handleEscapeKey(e);
    this.fireEvent("search-keydown", e);
  };

  handleEnterKey(e: KeyboardEvent) {
    if (isEnter(e)) {
      if (!this.open) {
        e.preventDefault();
      } else {
        this.Suggestion?.onEnter(e);
        this.fireDropdownItemClick(this.value);
      }
      this.handleSubmitValue();
    }
  }

  handleTabKey(e: KeyboardEvent) {
    if (isTabNext(e) && this.open) {
      this.Suggestion?.onTab(e);
    }
  }

  handleEscapeKey(e: KeyboardEvent) {
    if (isEscape(e)) {
      if (this.open) {
        this.Suggestion?.onEscape(e);
        this.handleClassToRoot();
      }
      this.fireEvent("handle-close-search");
    }
  }

  handleKeyDownPopover(e: KeyboardEvent) {
    if (this.open) {
      this.handleTabKeyList(e);
      this.handlePrevTabKeyList(e);
      this.handleDownKeyList(e);
      this.handleUpKeyList(e);
      this.handleEscapeKey(e);
    }
  }

  handleTabKeyList(e: KeyboardEvent) {
    if (isTabNext(e)) {
      this.Suggestion?.onKeyTabList(e);
      this.handleClassToRoot();
    }
  }

  handlePrevTabKeyList(e: KeyboardEvent) {
    if (isTabPrevious(e)) {
      this.Suggestion?.onPrevKeyTabList(e);
    }
  }

  handleDownKeyList(e: KeyboardEvent) {
    if (isDown(e)) {
      this.Suggestion?.onKeyDown(e);
    }
  }

  handleUpKeyList(e: KeyboardEvent) {
    if (isUp(e)) {
      this.Suggestion?.onKeyUp(e);
    }
  }

  handleFocus(): void {
    this.active = true;
    this.isFocusedOnTextfield = true;
    this.focusClasses = this._focusActiveClassName;
    this.labelActiveClass = (this.value || !this.labelAnimationDisabled) ? this._labelActiveClassName : "";
    this.labelAnimationClass = !this.labelAnimationDisabled ? this._labelAnimationClassName : "";
  }

  handleFocusout(): void {
    this.active = false;
    this.isFocusedOnTextfield = false;
    this.focusClasses = "";
  }

  onReset(e: MouseEvent): void {
    e.stopPropagation();
    this.handleReset();
  }

  handleReset(): void {
    this.value = "";
    this.showResetButton = false;
    this.onClosePopover();
    this.fireEvent("handle-value-reset");
    this.Suggestion?.resetFocusState();
    this.focus();
  }

  handleKeyDownReset(e: KeyboardEvent) {
    if (isTabPrevious(e) && this.open) {
      this.Suggestion?.handlePrevTabReset(e);
    }
    if (isEnter(e) || isSpace(e)) {
      e.preventDefault();
      e.stopPropagation();
      this.handleReset();
      this.getSearchInput()?.focus();
    }
  }

  private getSearchInput(): HTMLInputElement {
    return this.shadowRoot?.querySelector("[id='search-input']") as HTMLInputElement;
  }

  onVoice(e: MouseEvent): void {
    e.stopPropagation();
    if (this.speechRecognition) {
      this.onSpeechLaunch();
      this.onSpeechStart();
      this.onSpeechResult();
      this.onSpeechEnd();
    }
  }

  onSpeechLaunch(): void {
    if (!this.voiceActiveClass) {
      this.speechRecognition.start();
    } else {
      this.speechRecognition.stop();
    }
  }

  onSpeechStart(): void {
    this.speechRecognition.onstart = () => {
      this.voiceActiveClass = this._voiceActiveClassName;
      this.getSearchInput()?.focus();
    };
  }

  onSpeechResult(): void {
    this.speechRecognition.onresult = (e: any) => {
      const last = e.results.length - 1;
      this.value = e.results[last][0].transcript;
      this.speechRecognition.stop();
    };
  }

  onSpeechEnd(): void {
    this.speechRecognition.onend = () => {
      this.voiceActiveClass = "";
      this.handleSubmitValue();
      this.onShowResetButton();
    };
  }

  onShowResetButton(): void {
    if (this.value) {
      this.showResetButton = true;
    }
  }

  _handleResize() {
    this.setSearchWidth();
  }

  private setSearchWidth() {
    const searchContainer = (this.shadowRoot?.querySelector(".udex-search__container") as HTMLElement);

    if (searchContainer.offsetWidth !== this._searchWidth) {
      this._searchWidth = searchContainer.offsetWidth;
    }
  }

  get popoverStyles() {
    return {
      "width": this._searchWidth ? `${this._searchWidth}px` : "",
    };
  }

  get prefixPopoverStyles() {
    return {
      "width": this.getPrefixButton() ? `${this.getPrefixButton().offsetWidth + 1}px` : "",
    };
  }

  get isShowResetButton(): boolean {
    return this.showResetButton && !!this.value.length;
  }

  get noResultMessage(): string {
    return this.noResultText || UDExSearch.i18nBundle.getText(NO_MATCHES_FOUND) as string;
  }

  get showNoResultMessage(): boolean {
    return !this.suggestedList?.length && !this.recommendedList?.length;
  }

  get isShowSuggestedList(): boolean {
    return !!this.suggestedList?.length;
  }

  get isShowRecommendedList(): boolean {
    return !!this.recommendedList?.length;
  }

  get hasPopoverContent(): boolean {
    return !!(this.suggestedList?.length || this.recommendedList?.length);
  }

  get getAriaHasPopup(): string {
    return this.showSuggestedPopover ? "dialog" : "false";
  }

  get getSuggestionsPopoverId(): string {
    return SuggestionsPopoverId;
  }

  get isShowSubmitButton(): boolean {
    return this.showSubmitButton;
  }

  get suggestedHeading(): string | undefined {
    return this._suggestionLabel;
  }

  get recommendedHeading(): string | undefined {
    return this._recommendLabel;
  }

  get isShowSearchHomePrefix(): boolean {
    return this.isHomeSearchWithPrefixMode && !!this.dropdownPrefix.length;
  }

  get isHomeSearchMode(): boolean {
    return this.mode === Mode.HomeSearch;
  }

  get isHomeSearchWithPrefixMode(): boolean {
    return this.mode === Mode.HomeSearch && !!this.dropdownPrefix.length;
  }

  get classWithPrefix(): string {
    return this.isHomeSearchWithPrefixMode ? "udex-search-prefix" : "";
  }

  get homeSearchPopoverClass(): string {
    return this.isHomeSearchMode || this.isHomeSearchWithPrefixMode ? "udex-search-suggestions__root--home" : "";
  }

  get homeSearchWithPrefixPopoverClass(): string {
    return this.isHomeSearchWithPrefixMode ? "udex-search-suggestions__root--prefix" : "";
  }

  get searchButtonActiveClass(): string {
    return this.isHomeSearchMode && this.open ? "udex-search__submit-button--active" : "";
  }

  get isNotEmptyClass(): string {
    return this.value.length > 2 || this.focusClasses.length ? " udex-search__container--has-value" : "";
  }

  get isVoiceButton(): boolean {
    return this.showVoiceButton && this.isNotMozillaFirefox && this.isNotMozillaFirefoxIOS;
  }

  get accInfo() {
    return {
      "ariaLabel": this.accessibleName || undefined,
      "accessibleNameResetIcon": this.accessibleNameResetIcon || UDExSearch.i18nBundle.getText(ACCESSIBLE_NAME_RESET_ICON) as string,
      "accessibleNameSearchIcon": this.accessibleNameSearchIcon || UDExSearch.i18nBundle.getText(ACCESSIBLE_NAME_SEARCH_ICON) as string,
    };
  }

  get tooltipInfo() {
    return {
      "tooltipResetButton": this.tooltipResetButton || UDExSearch.i18nBundle.getText(TOOLTIP_RESET_BUTTON) as string,
      "tooltipVoiceButton": this.tooltipVoiceButton || UDExSearch.i18nBundle.getText(TOOLTIP_VOICE_BUTTON) as string,
      "tooltipSubmitButton": this.tooltipSubmitButton || UDExSearch.i18nBundle.getText(TOOLTIP_SUBMIT_BUTTON) as string,
    };
  }

  get suggestionsAccessibleName(): string {
    return UDExSearch.i18nBundle.getText(AVAILABLE_VALUES) as string;
  }
}

UDExSearch.define();

export default UDExSearch;
