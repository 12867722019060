/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Badge from "../../Badge.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Badge, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.interactive ? block1.call(this, context, tags, suffix) : block4.call(this, context, tags, suffix) } `;}
function block1 (this: Badge, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<button class="ui5-badge-root" title="${ifDefined(this._title)}" aria-roledescription="${ifDefined(this._roleDescription)}" aria-description="${ifDefined(this._valueState)}" @onclick=${this._onclick}><slot name="icon"></slot>${ this._semanticIconName ? block2.call(this, context, tags, suffix) : undefined }<span class="ui5-hidden-text">${ifDefined(this.badgeDescription)}</span>${ this.hasText ? block3.call(this, context, tags, suffix) : undefined }</button>`;}
function block2 (this: Badge, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="ui5-badge-semantic-icon" name="${ifDefined(this._semanticIconName)}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="ui5-badge-semantic-icon" name="${ifDefined(this._semanticIconName)}"></ui5-icon>`;}
function block3 (this: Badge, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="ui5-badge-text"><bdi><slot></slot></bdi></span>`;}
function block4 (this: Badge, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="ui5-badge-root" title="${ifDefined(this._title)}"><slot name="icon"></slot>${ this._semanticIconName ? block5.call(this, context, tags, suffix) : undefined }<span class="ui5-hidden-text">${ifDefined(this.badgeDescription)}</span>${ this.hasText ? block6.call(this, context, tags, suffix) : undefined }</div>`;}
function block5 (this: Badge, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="ui5-badge-semantic-icon" name="${ifDefined(this._semanticIconName)}"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="ui5-badge-semantic-icon" name="${ifDefined(this._semanticIconName)}"></ui5-icon>`;}
function block6 (this: Badge, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<span class="ui5-badge-text"><bdi><slot></slot></bdi></span>`;}


export default block0;