import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import property from "@ui5/webcomponents-base/dist/decorators/property.js";
import type I18nBundle from "@ui5/webcomponents-base/dist/i18nBundle.js";
import { getI18nBundle } from "@ui5/webcomponents-base/dist/i18nBundle.js";
import executeTemplate from "@ui5/webcomponents-base/dist/renderer/executeTemplate.js";
import {
  isEnter, isSpace, isBackSpace, isDelete,
} from "@ui5/webcomponents-base/dist/Keys.js";
import event from "@ui5/webcomponents-base/dist/decorators/event.js";
import Button from "@ui5/webcomponents/dist/Button.js";
import ResizeHandler, { ResizeObserverCallback } from "@ui5/webcomponents-base/dist/delegate/ResizeHandler.js";
import TextField from "./TextField.js";
import ValueState from "./types/common.js";
import StatusMessage from "./StatusMessage.js";
import { COUNTRY_SELECT } from "./generated/i18n/i18n-defaults.js";
import CountrySelectorTemplate from "./generated/templates/CountrySelectorTemplate.lit.js";
import SearchableListBaseTemplate from "./generated/templates/SearchableListBaseTemplate.lit.js";

// Styles
import CountrySelectorCss from "./generated/themes/CountrySelector.css.js";

import Search from "./Search.js";
import UDExDropdown from "./Dropdown.js";
import UDExList from "./List.js";
import { Country, CountryListDB } from "./data/CountryListISO3166.js";
import UDExSearchableListBase from "./SearchableListBase.js";
import UDExListItem from "./ListItem.js";
import "@ui5/webcomponents-icons/dist/navigation-down-arrow.js";
/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-country-selector</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/CountrySelector.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends UDExSearchableListBase
 * @tagname udex-country-selector
 * @public
 */
@customElement({
  tag: "udex-country-selector",
  renderer: litRender,
  styles: CountrySelectorCss,
  template: CountrySelectorTemplate,
  dependencies: [
    Search,
    UDExList,
    UDExListItem,
    UDExDropdown,
    TextField,
    Button,
    StatusMessage,
  ],
})

/**
 * Fired before the dropdown paneis opened.
 *
 * @event sap.ui.webc.web-components.CountrySelector#beforeOpen
 * @public
 */
@event("before-open")

/**
 * Fired after the dropdown pane is opened.
 *
 * @event sap.ui.webc.web-components.CountrySelector#afterOpen
 * @public
 */
@event("after-open")

/**
 * Fired before the dropdown pane is closed.
 *
 * @event sap.ui.webc.web-components.CountrySelector#beforeClose
 * @public
 */
@event("before-close")

/**
 * Fired after the dropdown pane is closed.
 *
 * @event sap.ui.webc.web-components.CountrySelector#afterClose
 * @public
 */
@event("after-close")

/**
 * Fired event when the user submitted the value in the search.
 *
 * @event sap.ui.webc.web-components.CountrySelector#changeSearch
 * @public
 */
@event("change-search", {
  detail: {
    value: { type: String },
  },
})

/**
 * Fired event when the user clicks on the reset button in the search.
 *
 * @event sap.ui.webc.web-components.CountrySelector#resetSearch
 * @public
 */
@event("reset-search")

/**
 * Fired event when the user clicks on the submitted icon in the search.
 *
 * @event sap.ui.webc.web-components.CountrySelector#submitSearch
 * @public
 */
@event("submit-search", {
  detail: {
    selectedItems: { type: Array },
  },
})

class CountrySelector extends UDExSearchableListBase {
  /**
   * Defines label text for the input field.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    label?: string;

  /**
   * Defines the accessible ARIA name of the component.
   *
   * @default ""
   * @public
   */
  @property()
    accessibleName!: string;

  /**
   * Receives id (or many ids) of the elements that label the component.
   * @default ""
   * @public
   */
  @property({ defaultValue: "" })
    accessibleNameRef!: string;

  /**
   * Defines whether the component is required.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    required!: boolean;

  /**
   * Allows control over the height.
   *
   * @public
   */
  @property({ type: String })
    height?: string;

  /**
   * Defines whether the component is in disabled state.
   * <br><br>
   * <b>Note:</b> A disabled component is completely noninteractive.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    disabled!: boolean;

  /**
   * Defines whether the component is read-only.
   * <br><br>
   * <b>Note:</b> A read-only component is not editable,
   * but still provides visual feedback upon user interaction.
   *
   * @default false
   * @public
   */
  @property({ type: Boolean })
    readonly!: boolean;

  /**
   * Defines whether the component is display-only.
   * <br><br>
   * <b>Note:</b> A display-only component is not editable,
   * but still provides visual feedback upon user interaction, input border is not visible.
   *
   * @default false
   * @public
   */

  @property({ type: Boolean })
    displayonly!: boolean;

  /**
   * Allows to define the initially preselected country.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    preselectedCountry!: string;

  /**
   * Allows to define the list of blocked country.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    blockedCountryCodes?: string;

  /**
   * Defines the placeholder of the search.
   *
   * @default ""
   * @public
   */
  @property({ type: String })
    searchPlaceholder!: string;

  /**
   * Defines the value state of the component.
   *
   * @default "Standard"
   * @public
   */
  @property({ type: ValueState, defaultValue: ValueState.Standard })
    valueState!: `${ValueState}`;

  /**
   * Defines supporting text under the country selector.
   *
   * @default ""
   * @public
   */
  @property({ type: String, defaultValue: "" })
    supportingText!: string;

  /**
   *
   * @public
   */
  @property({ type: Boolean })
    hideFlagImages?: boolean;

  @property({ type: Boolean })
    _open?: boolean = false;

  countryList: Country[] = CountryListDB;
  selectedCountry!: Country | undefined;

  _dropdown!: UDExDropdown;
  _search!: Search;

  _handleResizeBound: ResizeObserverCallback;

  static i18nBundle: I18nBundle;

  static async onDefine() {
    CountrySelector.i18nBundle = await getI18nBundle("@udex/web-components");
  }

  constructor() {
    super();
    this._shouldFilterItems = false;
    this._handleResizeBound = this._handleResize.bind(this);
    this._filteredItems = [];
  }

  onEnterDOM(): void {
    ResizeHandler.register(this, this._handleResizeBound);
  }

  onExitDOM(): void {
    ResizeHandler.deregister(this, this._handleResizeBound);
  }

  onBeforeRendering(): void {
    if (this.blockedCountryCodes?.length) {
      const blockedCountryCodesArray = this.blockedCountryCodes.split(",").map(code => code.trim().toUpperCase());
      this.countryList = this.countryList.filter(country => !blockedCountryCodesArray?.includes(country.iso.toUpperCase()));
    }

    if (!this.selectedCountry) {
      this.selectedCountry = this.countryList.find(country => country.iso.toUpperCase() === this.preselectedCountry.toUpperCase());
    }

    this.prepareCountrySelectorItems();

    if (!this._shouldFilterItems) {
      this._filteredItems = this.getProcessedItems();
    }
  }

  onAfterRendering() {
    this._dropdown = this.getDropdown();
    this._search = this.getSearch();
  }

  _dropdownBeforeOpen() {
    this.onResetSearch();
    this._open = true;
    this.fireEvent("before-open");
  }

  _dropdownAfterClose() {
    this._open = false;
    this.fireEvent("after-close");
  }

  _dropdownAfterOpen() {
    this.fireEvent("after-open");
  }

  _dropdownBeforeClose() {
    this.fireEvent("before-close");
  }

  get searchableList() {
    return executeTemplate(CountrySelector.searchableListBaseTemplate, this);
  }

  static get searchableListBaseTemplate() {
    return SearchableListBaseTemplate;
  }

  _handleResize() {
    this._dropdown.style.setProperty("--udex-dropdown-pane-width", `${this.offsetWidth}px`);
  }

  get ariaControls(): string | null | undefined {
    const list = this.shadowRoot!.querySelector<UDExList>("udex-list");
    return list?.getAttribute("id");
  }

  get selectedCountryFlagX() {
    return this.selectedCountry?.flagX;
  }

  get selectedCountryFlagY() {
    return this.selectedCountry?.flagY;
  }

  get selectedCountryName() {
    return this.selectedCountry ? this.getCountryLabel(this.selectedCountry.countryName, this.selectedCountry.localName) : "";
  }

  get flagAriaLabel():string {
    return this.getCountryLabel(this.selectedCountry?.countryName, this.selectedCountry?.localName) || "None";
  }

  get triggerIcon(): string {
    return this._open ? "navigation-up-arrow" : "navigation-down-arrow";
  }

  get countrySelectorClasses() {
    const baseClass = "udex-country-selector__dropdown";
    return this.hideFlagImages ? `${baseClass} ${baseClass}--hide-flag` : baseClass;
  }

  get countrySelectorTriggerClasses() {
    const baseClass = "udex-country-selector__trigger";
    const classes = [baseClass];

    if (this.disabled) {
      classes.push(`${baseClass}--disabled`);
    }

    if (this.readonly) {
      classes.push(`${baseClass}--readonly`);
    }

    if (this.displayonly) {
      classes.push(`${baseClass}--displayonly`);
    }

    return classes.join(" ");
  }

  get showFlagImages() {
    return !this.hideFlagImages;
  }

  get isOpenerDisabled() {
    return this.readonly || this.displayonly || this.disabled;
  }

  getItemTemplate(country: Country) {
    return this.hideFlagImages ? undefined : `<span class="udex-country-selector-item__image" style="background-position:${country.flagX}">`;
  }

  getCountryLabel(countryName?: string, localName?: string): string | null {
    return localName ? `${countryName} (${localName})` : countryName || null;
  }

  handleCloseSearch() {
    this._dropdown.onClose();
  }

  handleItemPress(e: CustomEvent) {
    this.onItemPress(e);
    const target = e.target as HTMLElement;
    const item: any = this.getItemFromList(target?.getAttribute("data-token-id") as string);
    this.selectedCountry = this.countryList.find(country => country.iso === item._id);
    this._dropdown.onClose();
  }

  handleKeyDown(e: CustomEvent) {
    if (this.disabled || this.readonly || this.displayonly) {
      return;
    }

    const keyEvent = e.detail as KeyboardEvent;
    const key = keyEvent.key;
    if (isEnter(keyEvent) || isSpace(keyEvent)) {
      if (this._dropdown.open) {
        this._dropdown.onClose();
      } else {
        this._dropdown.onTriggerClick();
      }
    } else if (isBackSpace(keyEvent) || isDelete(keyEvent)) {
      this.selectedCountry = undefined;
      this._render();
    } else if (this.isLetterKey(key)) {
      this._dropdown.onOpen(this.getInput());
      this._search.focus();
      this._search.value = key;
      this.onValueSearch(key);
    }
  }

  handleInputMouseDown(e: CustomEvent) {
    e.preventDefault();
  }

  onValueSearch(searchValue: string) {
    this._shouldFilterItems = true;
    this._filteredItems = this.filterItems(this.getProcessedItems(), searchValue);
    this.fireEvent("change-search", { value: searchValue });
  }

  handleTriggerClick() {
    if (this._dropdown.open) {
      this._dropdown.onClose();
    }
  }

  isLetterKey(key: string) {
    return /^[a-zA-Z]$/.test(key);
  }

  handleValueSearch(e:CustomEvent) {
    this.onValueSearch(e.detail.value as string);
  }

  handleSubmitSearch() {
    this.onSubmitSearch();
  }

  handleResetSearch() {
    this.onResetSearch();
  }

  prepareCountrySelectorItems(): void {
    this.items.length = 0;
    this.countryList.forEach(country => {
      const selected = country.iso.toUpperCase() === this.selectedCountry?.iso.toUpperCase();
      this.items.push({
        label: this.getCountryLabel(country.countryName, country.localName),
        innerHTML: this.getItemTemplate(country),
        selected,
        _id: country.iso.toUpperCase(),
      } as UDExListItem);
    });
  }

  getSearch(): Search {
    return this.shadowRoot!.querySelector<Search>("udex-search")!;
  }

  getDropdown(): UDExDropdown {
    return this.shadowRoot!.querySelector<UDExDropdown>("udex-dropdown")!;
  }

  getInput(): UDExDropdown {
    return this.shadowRoot!.querySelector<UDExDropdown>("udex-text-field")!;
  }

  get effectiveAriaLabel(): string {
    return this.label || this.accessibleName || CountrySelector.i18nBundle.getText(COUNTRY_SELECT);
  }
}

CountrySelector.define();

export default CountrySelector;
