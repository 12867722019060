import customElement from "@ui5/webcomponents-base/dist/decorators/customElement.js";
import litRender from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import CheckBox from "@ui5/webcomponents/dist/CheckBox.js";

// Styles
import checkboxCss from "@ui5/webcomponents/dist/generated/themes/CheckBox.css.js";
import CheckboxCss from "./generated/themes/Checkbox.css.js";

/**
 * @class
 *
 * <h3 class="comment-api-title">Overview</h3>
 *
 *
 * <h3>Usage</h3>
 *
 * For the <code>udex-checkbox</code>
 * <h3>ES6 Module Import</h3>
 *
 * <code>import "@udex/web-components/dist/Checkbox.js";</code>
 *
 * @constructor
 * @author SAP SE
 * @extends CheckBox
 * @tagname udex-checkbox
 * @public
 */
@customElement({
  tag: "udex-checkbox",
  renderer: litRender,
  styles: [checkboxCss, CheckboxCss],
  dependencies: [CheckBox],
})
class UDExCheckbox extends CheckBox {
}

UDExCheckbox.define();

export default UDExCheckbox;
