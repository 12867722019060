/* eslint no-unused-vars: 0 */
import { html, svg, repeat, classMap, styleMap, ifDefined, unsafeHTML, scopeTag } from "@ui5/webcomponents-base/dist/renderer/LitRenderer.js";
import type UI5Element from "@ui5/webcomponents-base/dist/UI5Element.js";
	import type Search from "../../Search.js";
	import type { ClassMapValue } from "@ui5/webcomponents-base/dist/types.js";
	
function block0 (this: Search, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<div class="udex-search__root ${ifDefined(this.classWithPrefix)}">${ this.isShowSearchHomePrefix ? block1.call(this, context, tags, suffix) : undefined }<form role="search" class="udex-search__container ${ifDefined(this.focusClasses)}${ifDefined(this.isNotEmptyClass)}">${ this.labelAnimationDisabled ? block2.call(this, context, tags, suffix) : block3.call(this, context, tags, suffix) }<div class="udex-search__icons">${ this.isShowResetButton ? block5.call(this, context, tags, suffix) : undefined }${ this.isVoiceButton ? block6.call(this, context, tags, suffix) : undefined }${ this.showSubmitButton ? block7.call(this, context, tags, suffix) : undefined }</div></form></div>`;}
function block1 (this: Search, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<div class="udex-search__prefix"><button type="button" role="combobox" aria-expanded="${ifDefined(this._prefixDropdownExpanded)}" aria-haspopup="true" aria-controls="udex-search-prefix" class="udex-search__prefix-button" @click="${this.handleDropdownClick}"><span class="udex-search__prefix-label">${ifDefined(this.labelPrefix)}</span><${scopeTag("ui5-icon", tags, suffix)} class="udex-search__prefix-icon" name="slim-arrow-down"></${scopeTag("ui5-icon", tags, suffix)}></button><${scopeTag("ui5-popover", tags, suffix)} id="udex-search-prefix" class="udex-search__prefix-popover" hide-arrow placement-type="Bottom" horizontal-align="Left" style="${styleMap(this.prefixPopoverStyles)}" @after-close="${this.handleAfterClosePrefixPopover}"><slot></slot></${scopeTag("ui5-popover", tags, suffix)}></div>` : html`<div class="udex-search__prefix"><button type="button" role="combobox" aria-expanded="${ifDefined(this._prefixDropdownExpanded)}" aria-haspopup="true" aria-controls="udex-search-prefix" class="udex-search__prefix-button" @click="${this.handleDropdownClick}"><span class="udex-search__prefix-label">${ifDefined(this.labelPrefix)}</span><ui5-icon class="udex-search__prefix-icon" name="slim-arrow-down"></ui5-icon></button><ui5-popover id="udex-search-prefix" class="udex-search__prefix-popover" hide-arrow placement-type="Bottom" horizontal-align="Left" style="${styleMap(this.prefixPopoverStyles)}" @after-close="${this.handleAfterClosePrefixPopover}"><slot></slot></ui5-popover></div>`;}
function block2 (this: Search, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<input id="search-input" type="search" name="search" class="udex-search__field" autocomplete="off" aria-autocomplete="list" aria-haspopup="${ifDefined(this.getAriaHasPopup)}" aria-label="Search" placeholder="${ifDefined(this.label)}" .value="${ifDefined(this.value)}" @input="${this.handleSearchChange}" @keydown="${this.handleKeyDown}" @focus="${this.handleFocus}" @focusout="${this.handleFocusout}" @blur="${this.handleBlur}" @click="${this.handleInputClick}" />`;}
function block3 (this: Search, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.label ? block4.call(this, context, tags, suffix) : undefined }<input id="search-input" type="search" name="search" class="udex-search__field" autocomplete="off" aria-autocomplete="list" aria-label="${ifDefined(this.accInfo.ariaLabel)}" aria-haspopup="${ifDefined(this.getAriaHasPopup)}" .value="${ifDefined(this.value)}" @input="${this.handleSearchChange}" @keydown="${this.handleKeyDown}" @focus="${this.handleFocus}" @focusout="${this.handleFocusout}" @blur="${this.handleBlur}" @click="${this.handleInputClick}" />`;}
function block4 (this: Search, context: UI5Element, tags: string[], suffix: string | undefined) { return html`<label for="search-input" class="${ifDefined(this.labelClasses)}">${ifDefined(this.label)}</label>`;}
function block5 (this: Search, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-button", tags, suffix)} id="reset-button" class="udex-search__button udex-search__reset-button" design="Default" tooltip="${ifDefined(this.tooltipInfo.tooltipResetButton)}" @click="${this.onReset}" @keydown="${this.handleKeyDownReset}"><${scopeTag("ui5-icon", tags, suffix)} class="udex-search__button-icon" accessible-name="${ifDefined(this.accInfo.accessibleNameResetIcon)}" accessible-role="presentation" name="decline"></${scopeTag("ui5-icon", tags, suffix)}></${scopeTag("ui5-button", tags, suffix)}>` : html`<ui5-button id="reset-button" class="udex-search__button udex-search__reset-button" design="Default" tooltip="${ifDefined(this.tooltipInfo.tooltipResetButton)}" @click="${this.onReset}" @keydown="${this.handleKeyDownReset}"><ui5-icon class="udex-search__button-icon" accessible-name="${ifDefined(this.accInfo.accessibleNameResetIcon)}" accessible-role="presentation" name="decline"></ui5-icon></ui5-button>`;}
function block6 (this: Search, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-button", tags, suffix)} id="voice-button" class="udex-search__button udex-search__voice-button ${ifDefined(this.voiceActiveClass)}" design="Default" icon="microphone" tooltip="${ifDefined(this.tooltipInfo.tooltipVoiceButton)}" @click="${this.onVoice}"></${scopeTag("ui5-button", tags, suffix)}>` : html`<ui5-button id="voice-button" class="udex-search__button udex-search__voice-button ${ifDefined(this.voiceActiveClass)}" design="Default" icon="microphone" tooltip="${ifDefined(this.tooltipInfo.tooltipVoiceButton)}" @click="${this.onVoice}"></ui5-button>`;}
function block7 (this: Search, context: UI5Element, tags: string[], suffix: string | undefined) { return html`${ this.nonInteractiveSubmitButton ? block8.call(this, context, tags, suffix) : block9.call(this, context, tags, suffix) }`;}
function block8 (this: Search, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-icon", tags, suffix)} class="udex-search__icon" accessible-name="${ifDefined(this.accInfo.accessibleNameSearchIcon)}" name="search"></${scopeTag("ui5-icon", tags, suffix)}>` : html`<ui5-icon class="udex-search__icon" accessible-name="${ifDefined(this.accInfo.accessibleNameSearchIcon)}" name="search"></ui5-icon>`;}
function block9 (this: Search, context: UI5Element, tags: string[], suffix: string | undefined) { return suffix ? html`<${scopeTag("ui5-button", tags, suffix)} id="submit-button" class="udex-search__button udex-search__submit-button ${ifDefined(this.searchButtonActiveClass)}" design="Default" icon="search" tooltip="${ifDefined(this.tooltipInfo.tooltipSubmitButton)}" @click="${this.onSubmit}"></${scopeTag("ui5-button", tags, suffix)}>` : html`<ui5-button id="submit-button" class="udex-search__button udex-search__submit-button ${ifDefined(this.searchButtonActiveClass)}" design="Default" icon="search" tooltip="${ifDefined(this.tooltipInfo.tooltipSubmitButton)}" @click="${this.onSubmit}"></ui5-button>`;}


export default block0;